import Vue from 'vue'

import {
	degrees,
	PDFDocument,
	rgb,
	StandardFonts
} from "pdf-lib";

import JSZip from 'jszip'
import FileSaver from 'file-saver'

import {
	Loading,
	// Notification,
	MessageBox
} from 'element-ui'; //项目已经全局引入element的话可以不单独引入

let loading; //定义loading变量

function startLoading() { //使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		text: 'loading……',
		background: 'rgba(0, 0, 0, 0.7)'
	})
}

function endLoading() { //使用Element loading-close 方法
	loading.close()
}

//pdf分割
export async function downloadSplitPDF(files, fileNames,zipName) {
	startLoading()
	const file = files[0];
	const reader = new FileReader();
	const fileContents = await new Promise((resolve, reject) => {
		reader.onload = function(event) {
			resolve(event.target.result);
		};
		reader.onerror = function(event) {
			reject(new Error("文件读取错误。"));
		};
		reader.readAsArrayBuffer(file);
	});
	const pdfDoc = await PDFDocument.load(fileContents);
	let fileListData = [];
	// 遍历每一页，将每一页保存为单独的 PDF 文件
	for (let i = 0; i < pdfDoc.getPageCount(); i++) {
		const pdfBytes = await PDFDocument.create();
		const [copiedPage] = await pdfBytes.copyPages(pdfDoc, [i]);
		pdfBytes.addPage(copiedPage);
		const mergedPdfFile = await pdfBytes.saveAsBase64();
		fileListData.push("data:application/pdf;base64," + mergedPdfFile);
		endLoading();
	}
	//压缩
	fileZipAndDownloadReName(fileListData, fileNames, zipName);
}

// 重命名文件名称-并压缩文件并下载
export function fileZipAndDownloadReName(fileList, fileNameList,zipName) {
	const zip = new JSZip()

	let promises = [];
	let loadingInstance = Loading.service({
		text: "loading compress..."
	});
	
	for(let i=0,ln=fileList.length;i<ln;i++){
		let item = fileList[i];
		let data = base64ToBlob(item);

		let fileName = "error_split" + '_' + i;
		if (fileNameList[i]) {
			fileName = fileNameList[i];
		}
		zip.file(fileName+'.pdf', data, {
			binary: true
		});
	}
	
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
		})
}

// base64转blob
function base64ToBlob(code) {
	let parts = code.split(";base64,");
	let contentType = parts[0].split(":")[1];
	let raw = window.atob(parts[1]);
	let rawLength = raw.length;
	let uInt8Array = new Uint8Array(rawLength);
	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}
	return new Blob([uInt8Array], {
		type: contentType
	});
}

//pdf合并
export async function downloadMergePDF(files) {
	startLoading()
	// const fileInput = document.getElementById("fileInput");
	// const files = fileInput.files;
	// if (files.length < 2) {
	//   alert("请至少选择两个PDF文件进行合并！");
	//   return;
	// }
	// let PDFDocument = await pdfjsLib.getDocument();
	// console.log('pdfjsLib',pdfjsLib);
	const mergedPdf = await PDFDocument.create();
	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const reader = new FileReader();
		const fileContents = await new Promise((resolve, reject) => {
			reader.onload = function(event) {
				resolve(event.target.result);
			};
			reader.onerror = function(event) {
				reject(new Error("文件读取错误。"));
			};
			reader.readAsArrayBuffer(file);
		});
		const pdf = await PDFDocument.load(fileContents);
		const copiedPages = await mergedPdf.copyPages(
			pdf,
			pdf.getPageIndices()
		);
		copiedPages.forEach((page) => {
			mergedPdf.addPage(page);
		});
	}
	const mergedPdfFile = await mergedPdf.saveAsBase64();
	// const downloadLink = document.createElement("a");
	// downloadLink.href = "data:application/pdf;base64," + mergedPdfFile;

	// let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());

	// downloadLink.download = +"merged_"+dateStr+".pdf";
	// downloadLink.click();

	let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
	// loadingInstance.close();
	// 生成二进制流
	FileSaver.saveAs("data:application/pdf;base64," + mergedPdfFile, "merged" + '_' + dateStr + '.pdf')

	endLoading();

}
// async function downloadMergePDF() {
//         const fileInput = document.getElementById("fileInput");
//         const files = fileInput.files;
//         if (files.length < 2) {
//           alert("请至少选择两个PDF文件进行合并！");
//           return;
//         }
//         const mergedPdf = await PDFLib.PDFDocument.create();
//         for (let i = 0; i < files.length; i++) {
//           const file = files[i];
//           const reader = new FileReader();
//           const fileContents = await new Promise((resolve, reject) => {
//             reader.onload = function (event) {
//               resolve(event.target.result);
//             };
//             reader.onerror = function (event) {
//               reject(new Error("文件读取错误。"));
//             };
//             reader.readAsArrayBuffer(file);
//           });
//           const pdf = await PDFLib.PDFDocument.load(fileContents);
//           const copiedPages = await mergedPdf.copyPages(
//             pdf,
//             pdf.getPageIndices()
//           );
//           copiedPages.forEach((page) => {
//             mergedPdf.addPage(page);
//           });
//         }
//         const mergedPdfFile = await mergedPdf.saveAsBase64();
//         const downloadLink = document.createElement("a");
//         downloadLink.href = "data:application/pdf;base64," + mergedPdfFile;
//         downloadLink.download = "merged.pdf";
//         downloadLink.click();
//       }