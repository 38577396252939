<template>
	<!-- 导入excel -->
	<!-- <el-dialog :title="$t('i18nn_45855d1e066e1764')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1100px" top="0"> -->
	<el-drawer :wrapperClosable="false" :title="'图片转PDF'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="1100px">
		<div style="margin-top: 10px;" v-loading="loadingUpload">
			<!-- <el-timeline> -->
			<!-- <el-timeline-item :timestamp="$t('i18nn_132d087e57aebb7d')" placement="top" type="primary"> -->
			<el-card style="margin-bottom: 10px;">
				<div slot="header" class="">
					<h3>
						<!-- <i class="el-icon-upload2"></i> -->
						<span><span>{{$t('i18nn_7f2f052a5da435ca')}}</span>图片<span>{{$t('i18nn_f156a9536049f461')}}</span></span>
					</h3>
				</div>

				<div>
					<!-- <input type="file" accept="image/jpeg,image/png,image/gif" multiple ref="pdfFile"
						@change="fileChange($event)" /> -->
						
						<el-upload
							ref="elUpload"
						  drag
						  multiple
							accept="image/jpeg,image/png,image/gif"
							:auto-upload="false"
							:action="''"
							:show-file-list="false"
							:on-preview="elFilePreview"
							:on-remove="elFileDel"
							:on-change="elFileChange">
						  <i class="el-icon-upload"></i>
						  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						  <div class="el-upload__tip" slot="tip">只能上传jpeg/png/gif文件</div>
						</el-upload>

					<el-table ref="multipleTable" :data="previewTableData" stripe :border="true" style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="name" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
						<el-table-column prop="size" :label="'大小(KB)'"></el-table-column>
						<!-- <el-table-column prop="data" :label="'data'">
									<template slot-scope="scope">
										<span v-if="scope.row.data">{{ scope.row.data.substring(0, 50) }}...</span>
									</template>
								</el-table-column> -->
						<el-table-column :label="$t('Storage.tableColumn.operation')" width="180px" align="left">
							<template slot-scope="scope">
								<div>
									<el-button type="danger" size="mini" icon="el-icon-minus"
										@click="delAction($event,scope.index)">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</div>
							</template>
						</el-table-column>

					</el-table>

					<div style="margin-top: 10px;">

						<el-button type="warning" @click="submitAction()" icon="el-icon-download">
							<span>转换为PDF并下载</span>
						</el-button>
						<el-button type="primary" plain @click="initData()" icon="">
							<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
						</el-button>
					</div>

				</div>
			</el-card>


			</el-timeline>

		</div>

	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	// import { priterPdfLocal, GetPrinterList, savePrtVal, getPrtVal, priterPdfTestLocal } from '@/utils/LodopPrinter.js';
	// import { filePdfBase64ZipAndDownload, downloadPdfFile } from '@/utils/fileZipDownload.js';
	import {
		ConvertImageToPdf
	} from '@/utils/pdfExport.js';
	export default {
		props: {

			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			}

		},
		components: {
			// HyElWhUpLoad
		},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,

				previewTableData: [],

				selectOption: {

				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化lodop
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.previewTableData = [];
				this.$nextTick(() => {
					try {
						this.$refs.pdfFile.value = '';
					} catch (e) {
						console.log(e);
						//TODO handle the exception
					}
				});
			},

			//删除
			delAction(event, index) {
				event.stopPropagation();
				this.previewTableData.splice(index, 1);
			},

			// fileChange(el) {
			// 	this.$nextTick(() => {
			// 		console.log('pdfFile', this.$refs.pdfFile, this.$refs.pdfFile.value);
			// 		console.log('files', this.$refs.pdfFile.files);
			// 		// var files = el.target.files;
			// 		var files = this.$refs.pdfFile.files;
			// 		console.log('el, files', el, files);
			// 		files.forEach(item => {
			// 			this.previewTableData.push({
			// 				name: item.name,
			// 				data: item,
			// 				size: (item.size / 1000).toFixed(2)
			// 			});
			// 		})
			// 		// try {
			// 		// 	this.$refs.pdfFile.value = '';
			// 		// } catch (e) {
			// 		// 	console.log(e);
			// 		// 	//TODO handle the exception
			// 		// }
			// 	});
			// },
			
			elFilePreview(file){
				console.log('elFilePreview',file);
			},
			elFileChange(file, fileList){
				console.log('elFileChange',file, fileList);
				// var files = fileList;
				// files.forEach(item=>{
				// 	this.previewTableData.push({
				// 		name: item.raw.name,
				// 		data: item.raw,
				// 		size: (item.size/1000).toFixed(2)
				// 	});
				// });
				
				this.previewTableData.push({
					name: file.raw.name,
					data: file.raw,
					size: (file.raw.size/1000).toFixed(2)
				});
				
				this.$nextTick(()=>{
					this.$refs.elUpload.clearFiles();
				});
			},
			elFileDel(file, fileList){
				console.log('elFileDel',file, fileList);
			},

			async submitAction() {
				this.loadingUpload = true;

				let files = [];
				this.previewTableData.forEach(item => {
					files.push({
						name: item.name,
						data: item.data,
					});
				});

				// await downloadMergePDF(files);
				let name = "ConvertImageToPdf";
				let option = {
					// size: "3"
				};

				await ConvertImageToPdf(files, name, option);

				this.loadingUpload = false;

			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

</style>