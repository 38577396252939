<template>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_ebd257df8f7efc53')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1000px">
		<el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane name="first">
				<span slot="label"><i class="el-icon-tickets"></i><span>{{$t('i18nn_127af9ed95c3c6be')}}</span></span>
				<div>
					<whLabelFileNameMatch :openTime="FileLabelUploadOpenTime" :matchType="'0'" :fileKey="FileLabelUploadKey" @success="FileLabelUploadSuccess" @close="closeBack"></whLabelFileNameMatch>
					
					
				</div>
			</el-tab-pane>
			<el-tab-pane name="second">
				<span slot="label"><i class="el-icon-tickets"></i><span>按文件名匹配运单号</span></span>
				<div>
					<whLabelFileNameMatch :openTime="FileLabelUploadOpenTime2" :matchType="'1'" :fileKey="FileLabelUploadKey" 
					@success="FileLabelUploadSuccess" @close="closeBack"></whLabelFileNameMatch>
					
				</div>
			</el-tab-pane>
			<el-tab-pane name="third">
				<span slot="label"><i class="el-icon-tickets"></i><span>{{$t('i18nn_b3c2b18eb57891e5')}}</span></span>
				<div>
					<whLabelFileAutoMatch :openTime="FileLabelUploadOpenTime3" :fileKey="FileLabelUploadKey" @success="FileLabelUploadSuccess" @close="closeBack"></whLabelFileAutoMatch>
					
				</div>
			</el-tab-pane>
		</el-tabs>

	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import whLabelFileNameMatch from '@/components/StorageCenter/shipments/whLabelFileNameMatch.vue';
	
	import whLabelFileAutoMatch from '@/components/StorageCenter/shipments/whLabelFileAutoMatch.vue';
	
	export default {

		props: {

			openTime: {

			},

		},
		components: {
			whLabelFileNameMatch,
			whLabelFileAutoMatch
		},
		data() {
			return {
				dialogFile: false,
				activeName: 'first',
				FileLabelUploadOpenTime:'',
				FileLabelUploadOpenTime2:'',
				FileLabelUploadOpenTime3:'',
				FileLabelUploadKey:'',
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.FileLabelUploadOpenTime = new Date().getTime();
				this.FileLabelUploadOpenTime2 = new Date().getTime();
				this.FileLabelUploadOpenTime3 = new Date().getTime();
			},
			handleClick(tab, event) {
			  console.log(tab, event);
			  if('second'==tab.name){
			  	this.FileLabelUploadOpenTime2 = new Date().getTime();
			  } else if('third'==tab.name){
			  	this.FileLabelUploadOpenTime3 = new Date().getTime();
			  }
			},
			FileLabelUploadSuccess(){
				this.$emit('success')
			},
			closeBack(){
				this.dialogFile = false;
			}
			
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>