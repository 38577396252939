<template>
	<!-- 导入excel -->
	<!-- <el-dialog :title="$t('i18nn_45855d1e066e1764')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1100px" top="0"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_45855d1e066e1764')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1100px">
		<div style="margin-top: 10px;" v-loading="loadingUpload">
			<el-timeline>
				<el-timeline-item :timestamp="$t('i18nn_132d087e57aebb7d')" placement="top" type="primary">
					<el-card style="margin-bottom: 10px;">
						<div slot="header" class="">
							<h3>
								<!-- <i class="el-icon-upload2"></i> -->
								<span><span>{{$t('i18nn_7f2f052a5da435ca')}}</span>PDF<span>{{$t('i18nn_f156a9536049f461')}}</span></span>
							</h3>
						</div>

						<div>
							<!-- <input type="file" accept="application/pdf" ref="pdfFile" @change="fileChange($event)" /> -->
							
							<el-upload
								ref="elUpload"
							  drag
							  :multiple="false"
								accept="application/pdf"
								:auto-upload="false"
								:action="''"
								:show-file-list="false"
								:on-preview="elFilePreview"
								:on-remove="elFileDel"
								:on-change="elFileChange">
							  <i class="el-icon-upload"></i>
							  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							  <div class="el-upload__tip" slot="tip">只能上传pdf文件</div>
							</el-upload>
							
							<el-table ref="multipleTable" :data="previewTableData" stripe :border="true" style="width: 100%" size="small">
								<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
								<el-table-column prop="name" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
								<el-table-column prop="base64" :label="'code:base64'">
									<template slot-scope="scope">
										<span v-if="scope.row.base64">{{ scope.row.base64.substring(0, 50) }}...</span>
									</template>
								</el-table-column>
							</el-table>

							<!-- <HyElWhUpLoad
								ref="HyElWhUpLoad"
								:listType="''"
								:watchKey="fileWatchKey"
								:fileListShow="fileList"
								:fileId="fileId"
								:folder="'storage/WhPdfSplit/'"
								:fileKey="'WhPdfSplit'"
								:bucket="'hytx-oss-wh'"
								:accept="'application/pdf'"
								:maxSizeMB="50"
								:limit="1"
								v-on:UploadSuccessUrl="changeFileUrl"
							></HyElWhUpLoad>
							
							<div v-if="listUrl && listUrl.length > 0">
							<div style="margin: 10px 0;">
								<el-button type="warning" @click="initFile()" size="small" icon="el-icon-refresh-left" >{{$t('i18nn_aaf7f7f3df61a6a5')}}</el-button>
							</div>
								<el-table :data="listUrl" stripe :border="true" style="width: 100%" size="small">
									<el-table-column prop="fileName" :label="$t('i18nn_1aa9b398f37906a4')"></el-table-column>
									<el-table-column prop="filePath" :label="$t('i18nn_d54012286fece209')">
									</el-table-column>
								</el-table>
								</div> -->
							<!-- <input type="file" accept="application/pdf" ref="pdfFile" multiple @change="fileChange($event)" /> -->
						</div>
					</el-card>
				</el-timeline-item>
				<el-timeline-item :timestamp="$t('i18nn_9c17e9aea46e0c8a')" placement="top" type="primary">
					<el-card style="margin-bottom: 10px;">
						<div slot="header" class="">
							<h3>
								<!-- <i class="el-icon-edit-outline"></i> -->
								<!-- <span>{{$t('i18nn_71be22ba63b84f22')}}</span> -->
								<span>{{$t('i18nn_45855d1e066e1764')}}</span>
							</h3>
						</div>
						<!-- <div style="margin-bottom: 5px;">
							<el-input-number v-model="degrees" :step="1"></el-input-number>
						</div> -->
						<div style="margin-bottom: 5px;">
							<div>
								<span>{{$t('i18nn_a2b42f1c9bf76168')}}</span>：
								<span>{{degrees}}</span>°
							</div>
						</div>
						<div style="margin-top: 10px;">
							<el-button type="primary" @click="rotateAction()" icon="el-icon-refresh-right">
								<span>{{$t('i18nn_45855d1e066e1764')}}</span>
								<span>90°</span>
							</el-button>
							<el-button type="primary" @click="rotateAction('reset')" plain icon="el-icon-refresh">
								<span>{{$t('i18nn_0abac39c74830494')}}</span>
							</el-button>
						</div>
						
						<!-- <el-tag type="success" v-if="tableData && tableData.length > 0">共拆分出&nbsp;{{ tableData.length }}&nbsp;个文件</el-tag> -->
					</el-card>
				</el-timeline-item>
				<el-timeline-item :timestamp="$t('i18nn_e7318027d521dd7c')" placement="top" type="primary">
					<!-- <el-tabs v-model="activeName" type="card"> -->
					<!-- <el-tab-pane :label="$t('i18nn_245883c80f181c4a')" name="first"> -->
					<el-card style="margin-bottom: 10px;">
						<div slot="header" class="">
							<h3>
								<!-- <i class="el-icon-download"></i> -->
								<span>PDF<span>{{$t('i18nn_423aaa7563e762bb')}}</span></span>
							</h3>
						</div>
						<el-button type="primary" @click="PdfBase64ZipAction()" icon="el-icon-download">{{$t('i18nn_245883c80f181c4a')}}</el-button>

						<ul id="pdf_view" style="width: 100%; height: 500px; overflow: auto;"></ul>

						<!-- <el-tag type="success" v-if="tableData && tableData.length > 0">共拆分出&nbsp;{{ tableData.length }}&nbsp;个文件</el-tag> -->
					</el-card>
					<!-- </el-tab-pane> -->
					<!-- <el-tab-pane :label="$t('i18nn_04e722a308964860')" name="second"> -->
					<!-- <el-card style="margin-bottom: 10px;">
								<div slot="header" class="">
									<h3><span>预览PDF和文件名</span></h3>
								</div>
								<el-button type="primary" @click="PdfListShowAction()" icon="el-icon-view">{{$t('i18nn_d71aefb39490bc0d')}}</el-button>
								<ul id="pdf_view" style="width: 100%; height: 500px; overflow: auto;"></ul>
							</el-card> -->
					<!-- </el-tab-pane> -->
					<!-- </el-tabs> -->

					<!-- <el-card style="margin-bottom: 10px;">
						<div slot="header" class="">
							<h3>
								<span>预览pdf和文件名</span>
							</h3>
						</div>
						<el-button type="primary" @click="PdfListShowAction()">pdf显示测试</el-button>
						<div id="pdf_view" style="width: 100%; height: 500px; overflow: auto;"></div>

					</el-card> -->
				</el-timeline-item>
				<!-- <el-timeline-item :timestamp="$t('i18nn_dff5b3da08659f44')" placement="top" type="primary">
					<el-card style="margin-bottom: 10px;">
						<div slot="header" class="">
							<h3>
								<span>PDF下载并压缩</span>
							</h3>
						</div>
						<el-button type="primary" @click="PdfBase64ZipAction()" icon="el-icon-download">{{$t('i18nn_d5a306be96327a1c')}}</el-button>
					</el-card>
				</el-timeline-item> -->
			</el-timeline>

			<!-- <el-card style="margin-bottom: 10px;">
				<div slot="header" class="">
					<h3>
						<i class="el-icon-notebook-2"></i>
						<span>{{$t('i18nn_1ca7506b642073ca')}}</span>
					</h3>
				</div>
				<div style="color:red;">单号列表(一行一个,且按PDF顺序匹配)：</div>
				<el-input
					type="textarea"
					:autosize="{ minRows: 5, maxRows: 10 }"
					size=""
					clearable
					show-word-limit
					maxlength="1000"
					:placeholder="'一行一个单号(按PDF顺序匹配)'"
					style="width: 100%;vertical-align: middle; "
					v-model="fileNameStr"
				></el-input>

				<el-button type="primary" @click="submitUpload()">{{$t('i18nn_204ffab8a6e01870')}}</el-button>

				<div id="pdf_view" style="width: 100%; height: 500px; overflow: auto;"></div>
				<el-button type="primary" @click="TestAction()">{{$t('i18nn_4ba633ab624e491d')}}</el-button>

				<el-button type="primary" @click="TestAction2()">pdf显示测试</el-button>
			</el-card> -->
		</div>

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
			<!-- <el-button type="primary" @click="printerAction2()">{{$t('i18nn_886072e393710563')}}</el-button> -->
			<!-- <el-button type="primary" @click="printerAction2()">printerAction2</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
// import { priterPdfLocal, GetPrinterList, savePrtVal, getPrtVal, priterPdfTestLocal } from '@/utils/LodopPrinter.js';
import { filePdfBase64ZipAndDownload, downloadPdfFile } from '@/utils/fileZipDownload.js';
import { pdfSplitView } from '@/utils/pdfUtils.js';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	props: {
		// mobile:"",
		// isShow: {
		//   default: function() {
		//     return false;
		//   },
		//   type: Boolean
		// },
		openTime: {
			// default: function() {
			//   return '';
			// },
			// type: String
		}
		// fileList: {
		//   default: function() {
		//     return [];
		//   },
		//   type: Array
		// }
	},
	components: {
		// HyElWhUpLoad
	},
	data() {
		return {
			dialogFile: false,
			loadingUpload: false,

			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,

			localFile: '',

			// fileNameStr: '',

			activeName: 'first',
			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,
			degrees: 0,
			// saveErr: false,
			previewTableData: [],
			listUrl: [],
			// imgb:'',
			filterData: {
				// size:'1',
				// hasView:'1'
			},
			tableData: [],
			// printerVal: '',
			// printerList: [],
			selectOption: {
				// sizeList:[
				// 	{
				// 		value: '1',
				// 		label: '4in*6in',
				// 	},
				// 	{
				// 		value: '2',
				// 		label: this.$t('i18nn_afa4dddf5a6214d4'),
				// 	},
				// ],
				// viewList:[
				// 	{
				// 		value: '1',
				// 		label: this.$t('i18nn_9a8a031ac6ac8473'),
				// 	},
				// 	{
				// 		value: '2',
				// 		label: this.$t('i18nn_04e722a308964860'),
				// 	},
				// ],
			}
		};
	},
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime');
			this.initData();
			//初始化lodop
			this.dialogFile = true;

			// try{
			// 	let LODOP = getCLodop();
			// 	console.log('LODOP',LODOP);
			// 	if(LODOP){
			// 		this.dialogFile = true;
			// 	} else {
			// 		this.$message.warning("初始化Clodop失败,请先升级或下载")
			// 	}
			// }catch(e){
			// 	//TODO handle the exception
			// 	this.$message.warning("初始化Clodop异常,请先升级或下载")
			// 	console.log(e);
			// }
		}
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.initData();
	},
	methods: {
		initData() {
			this.tableData = [];
			this.listUrl = [];
			this.degrees =0;
			// this.initFile();
			// this.$nextTick(() => {
			// 	try {
			// 		this.$refs.pdfFile.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// });
			// this.initPriterList();
			//设置上次打印机的值
			// this.printerVal = getPrtVal();
			this.previewTableData = [];
			this.$nextTick(() => {
				try {
					this.$refs.pdfFile.value = '';
				} catch (e) {
					console.log(e);
					//TODO handle the exception
				}
			});
			//清空 画布 dom
			this.$nextTick(() => {
				let pdfView = document.getElementById('pdf_view');
				pdfView.innerHTML = '';
			});
		},
		// fileChange(el) {
		// 	this.$nextTick(() => {
		// 		console.log(this.$refs.pdfFile, this.$refs.pdfFile.value);
		// 		var files = el.target.files;
		// 		console.log(el, files);
		// 		files.forEach(item => {
		// 			var reader = new FileReader();
		// 			reader.readAsDataURL(item); // 解析成base64格式
		// 			reader.onload = res => {
		// 				// console.log(res.target.result);        // 解析后的数据，如下图
		// 				// this.previewTableData.push({
		// 				// 	name:item.name,
		// 				// 	localPath:this.$refs.pdfFile.value,
		// 				// 	base64:res.target.result
		// 				// })

		// 				let fileName = item.name;
		// 				// fileName = fileName.substring(fileName.lastIndexOf("\\")+1);
		// 				fileName = fileName.substring(0, fileName.lastIndexOf('.'));
		// 				console.log('this.$refs.pdfFile.value',this.$refs.pdfFile.value);
		// 				this.previewTableData = [
		// 					{
		// 						name: fileName,
		// 						localPath: this.$refs.pdfFile.value,
		// 						base64: res.target.result,
		// 					}
		// 				];
		// 			};
		// 		});
		// 	});
		// },
		
		elFilePreview(file){
			console.log('elFilePreview',file);
		},
		elFileChange(file, fileList){
			console.log('elFileChange',file, fileList);
			// var files = fileList;
			// files.forEach(item=>{
			// 	this.previewTableData.push({
			// 		name: item.raw.name,
			// 		data: item.raw,
			// 		size: (item.size/1000).toFixed(2)
			// 	});
			// });
			
			// files.forEach(itemP => {
				let item = file.raw;
				var reader = new FileReader();
				reader.readAsDataURL(item); // 解析成base64格式
				reader.onload = res => {
					// console.log(res.target.result);        // 解析后的数据，如下图
					// this.previewTableData.push({
					// 	name:item.name,
					// 	localPath:this.$refs.pdfFile.value,
					// 	base64:res.target.result
					// })
			
					let fileName = item.name;
					// fileName = fileName.substring(fileName.lastIndexOf("\\")+1);
					fileName = fileName.substring(0, fileName.lastIndexOf('.'));
			
					this.previewTableData = [
						{
							name: fileName,
							// localPath: item.raw,
							base64: res.target.result,
						}
					];
				};
			// });
			
			this.$nextTick(()=>{
				this.$refs.elUpload.clearFiles();
			});
		},
		elFileDel(file, fileList){
			console.log('elFileDel',file, fileList);
		},

		// initFile(){
		// 	this.listUrl = [];
		// 	this.$nextTick(() => {
		// 		try {
		// 			this.$refs.HyElWhUpLoad.initData();
		// 			this.$refs.HyElWhUpLoad.clearFileList();
		// 		} catch (err) {
		// 			console.log(err);
		// 		}
		// 	});
		// },
		//上传回调
		// changeFileUrl(val) {
		// 	console.log('changeFileUrl', val);
		// 	let listUrl = val.map(item => {
		// 		// "filePath":"","fileName"：“”
		// 		return {
		// 			filePath: item.url,
		// 			fileName: item.name
		// 		};
		// 	});
		// 	console.log('listUrl', listUrl);
		// 	// this.$alert('上传成功！共上传 ' + listUrl.length + ' 个附件', this.$t('tips.tipsTitle'), {
		// 	//   type: 'success'
		// 	//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 	// });
		// 	this.listUrl = listUrl;
		// 	if (this.listUrl.length > 0 && this.listUrl[0].filePath) {
		// 		this.getPdfSplitData(this.listUrl[0].filePath);
		// 	} else {
		// 		this.$message.warning(this.$t('i18nn_8ca9d59119d46247'));
		// 	}

		// 	// this.initFile();
		// 	// this.$emit('success',listUrl);

		// 	// this.dialogFile = false;
		// 	// this.myForm.dataList = listUrl;
		// 	// this.myForm.dataList = val;

		// 	// this.submitExcelFormAction();
		// 	// this.submitExcelFormData();
		// },
		
		//
		
		//旋转
		rotateAction(degrees) {
			if('reset'===degrees){//复位重置
				this.degrees = 0;
			} else {//自动加90度
				if(0==this.degrees){
					this.degrees = 90;
				} else {
					this.degrees = 90+this.degrees;
				}
			}
			
			let base64 = this.previewTableData[0].base64;
			base64 = base64.substring(28, base64.length);
			this.getPdfData(base64, this.degrees);
		},
		//获取旋转后的的pdf的base64数据
		getPdfData(base64, degrees) {
			console.log('getPdfData', base64, degrees);
			// let fileNameArr = this.fileNameStr.splice('\n').trim();
			// let fileNameArr = this.fileNameStr.split('\n').filter(function(s) {
			// 	return s && s.trim();
			// });
			// if (fileNameArr.length <= 0) {
			// 	this.$message.warning(this.$t('i18nn_818f8e46c3baa256'));
			// 	return;
			// }
			this.loadingUpload = true;
			this.$http
				.put(this.$urlConfig.WhPdfRotate, {
					base64: base64,
					degrees: degrees
				})
				.then(({ data }) => {
					console.log('查询，请求成功');
					console.log(data);
					this.loadingUpload = false;
					if (200 == data.code && data.data) {
						// let tableData = [data.data];
						// let name = this.previewTableData[0];
						this.tableData = [
							{
								data: data.data,
								name: this.previewTableData[0].name
							}
						];
						// this.tableData = tableData.map(item => {
						// 	return {
						// 		data: item.pdfContents,
						// 		name: item.fileName
						// 	};
						// });
						//预览
						this.PdfListShowAction();
						this.$message.success(this.$t('tips.submitSuccess'));
					} else {
						if (!data.msg) {
							data.msg = this.$t('tips.submitError');
						}
						this.$message.warning(data.msg);
					}
				})
				.catch(error => {
					console.log(error);
					console.log('查询接口，请求失败');
					this.loadingUpload = false;
					this.$message.error(this.$t('tips.requestErrorData'));
				});
		},
		//初始化打印机列表
		// initPriterList() {
		// 	// this.$nextTick(() => {
		// 	// 	Create_Printer_List('printerList');
		// 	// });
		// 	let printerList = GetPrinterList();
		// 	console.log('printerList', printerList);
		// 	this.printerList = printerList;
		// },
		// fileChange(el) {
		// 	this.$nextTick(() => {
		// 		console.log(this.$refs.pdfFile, this.$refs.pdfFile.value);

		// 		var files = el.target.files;
		// 		console.log(el, files);
		// 		files.forEach(item => {
		// 			var reader = new FileReader();
		// 			reader.readAsDataURL(item); // 解析成base64格式
		// 			reader.onload = res => {
		// 				console.log(res.target.result); // 解析后的数据，如下图

		// 				this.tableData.push({
		// 					name: item.name,
		// 					localPath: this.$refs.pdfFile.value,
		// 					base64: res.target.result
		// 				});
		// 			};
		// 		});

		// 		// this.tableData = files.map(item=>{
		// 		// 	return {
		// 		// 		name: item.name
		// 		// 	}
		// 		// });
		// 	});
		// },
		// printerAction(){
		// 	console.log(this.tableData);
		// 	this.$message.success('共 '+this.tableData.length+' 个文件，正在准备');
		// 	let size = {
		// 		width:'100%',
		// 		height: '100%'
		// 	};
		// 	if('1'==this.filterData.size){
		// 		size = {
		// 			width:'4in',
		// 			height: '6in'
		// 		};
		// 	} else if('2'==this.filterData.size) {
		// 		size = {
		// 			width:'100%',
		// 			height: '100%'
		// 		};
		// 	}

		// 	// let hasView = false;
		// 	// if('1'==this.filterData.hasView){
		// 	// 	hasView = false;
		// 	// } else if('2'==this.filterData.hasView) {
		// 	// 	hasView = true;
		// 	// }

		// 	priterPdfLocal2('localPdfFile',
		// 		this.tableData.map(item=> {
		// 			return item.base64
		// 		}),
		// 		size
		// 	);
		// 	//  pdf2imgShow(this.tableData[0].base64).then(res =>{
		// 	// 	console.log(res);
		// 	// 	this.imgb = res[0];
		// 	// })
		// 	this.dialogFile = false;
		// },
		//pdf展示
		PdfListShowAction() {
			// let base64 = this.tableData[0].base64;
			// base64 = base64.substring(28, base64.length - 1);
			//清空 画布 dom
			this.$nextTick(() => {
				let pdfView = document.getElementById('pdf_view');
				pdfView.innerHTML = '';
			});
			this.loadingUpload = true;
			if (this.tableData.length > 0) {
				this.$nextTick(async () => {
					for (let i = 0, ln = this.tableData.length; i < ln; i++) {
						let pdfData = this.tableData[i];
						await pdfSplitView(pdfData);
					}
					this.loadingUpload = false;
				});
			} else {
				this.loadingUpload = false;
				this.$message.warning(this.$t('tips.no_data'));
			}
		},
		//下载base64文件压缩包
		PdfBase64ZipAction() {
			let base64Arr = this.tableData;
			// data:application/pdf;base64,
			// data:application/octet-stream;base64,
			// let base64Arr = [{
			// 	name:'test',
			// 	data:'JVBERi0xLjcKJfbk/N8KMSAwIG9iago8PAovTmFtZXMgPDwKL0Rlc3RzIDIg...'
			// }]
			// let base64Arr = [{
			// 	name:'test2',
			// 	data:'JVBERi0xLjcKJfbk/N8KMSAwIG9iago8PAovTmFtZXMgPDwKL...'
			// }]
			console.log('PdfBase64ZipAction');
			if (this.tableData.length > 0) {
				filePdfBase64ZipAndDownload(base64Arr, 'pdfFileRoate');
				// downloadPdfFile(base64Arr[0].data, base64Arr[0].name);
			} else {
				this.$message.warning(this.$t('tips.no_data'));
			}
		}

		// printTest2(){
		// 	priterPdfTestLocal();
		// },
		// printerAction2(){
		// 	console.log(this.tableData);
		// 	//保存此次打印机的值
		// 	savePrtVal(this.printerVal);

		// 	this.$message.success('共 '+this.tableData.length+' 个文件，正在准备');
		// 	// let hasView = false;
		// 	// if('1'==this.filterData.hasView){
		// 	// 	hasView = false;
		// 	// } else if('2'==this.filterData.hasView) {
		// 	// 	hasView = true;
		// 	// }
		// 	priterPdfLocal('localPdfFile',
		// 		this.tableData.map(item=> {
		// 			return item.base64
		// 		}),
		// 		this.printerVal
		// 	);
		// 	this.dialogFile = false;
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
#pdf_view {
	background-color: #f5f5f5;
	display: flex;
	flex-wrap: wrap;
	border: 2px solid #ccc;
	li {
		margin: 5px;
		font-size: 16px;
		line-height: 100%;
		color: #409eff;
		canvas {
			display: block;
			// margin: 5px;
		}
	}
}
</style>
