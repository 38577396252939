<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_e6a250894a04db4c')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_75247a5e05331f7f')" append-to-body :visible.sync="dialog" :direction="'rtl'" size="900px"> -->
	<div v-loading="loading_load">

		<div>
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh2">
				<el-table-column type="index" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'70' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<!-- <el-tag type="danger"
										v-else-if="'70'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag> -->
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				<!-- 
							<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')">
								<template slot-scope="scope">
									<el-tag type="" v-if="'0' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
									<el-tag type="success"
										v-else-if="'10' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
									<el-tag type="warning"
										v-else-if="'20' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
									<el-tag type="danger"
										v-else-if="'30'==scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
									<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
								</template>
							</el-table-column> -->
				<el-table-column prop="relationNo" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>

				<el-table-column prop="matchTxt" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>

				<el-table-column prop="reUrl" :label="$t('i18nn_1324a1d91ae3de53')" show-overflow-tooltip>
					<template slot-scope="scope">
						<a :href="scope.row.reUrl" target="_blank">{{ scope.row.reUrl }}</a>
					</template>
				</el-table-column>

				<el-table-column prop="indexPage" :label="$t('i18nn_a5d008a482d2b005')"></el-table-column>

				<!-- <el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column> -->
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')">
					<template slot-scope="scope">
						<div>
							<div>
								<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:<span>{{scope.row.createTime}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:<span>{{scope.row.updateTime}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right" align="left">
						    <template slot-scope="scope">
						      <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
						    </template>
						  </el-table-column> -->
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>

		</div>

	</div>


	<!-- <div slot="footer" class="dialog-footer"> -->
	<!-- <el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
	<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
	<!-- </div> -->
	<!-- </el-drawer> -->
	<!-- </el-dialog> -->
</template>
<script>
	// import barcode from '@/components/Common/barcode.vue';

	export default {
		props: {
			openTime: {},
			parentId: {
				default: function() {
					return '';
				},
				type: String
			},
			statusList: {
				default: function() {
					return [];
				},
				type: Array
			}
		},
		components: {
			// barcode
		},
		data() {
			return {
				// dialog: false,
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'N', //N降序，Y升序
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime whLabelFileAutoMatchDetPage');
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			console.log('mounted whLabelFileAutoMatchDetPage');
			// console.log(this.openTime);
			if(this.parentId && this.statusList && this.statusList.length>0){
				this.initData();
			}
		},
		methods: {
			initData() {
				this.tableData = [];
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
			},
			//分页的筛选项数据
			pageFilterData() {
				return {
					parentId: this.parentId,
					statusList: this.statusList
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
					},
					this.pageFilterData()
				);
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.whMatchingLabelPdfRec, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						// console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			// getListData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.whMatchingLabelPdfRec, {
			// 			offset: "0",
			// 			limit: "5000",
			// 			parentId: this.id ? this.id : null,
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				// this.tableData = data.rows;
			// 				let tableData = data.rows;
			// 				// 30-拆分完成
			// 				// 35-处理中
			// 				// 40-处理成功
			// 				// 50-处理失败
			// 				// 55-label pdf上传成功
			// 				// 60-匹配成功
			// 				// 70-匹配失败

			// 				let allTabData = [{
			// 					name:this.$t('i18nn_379c907d12f5c9f5'),
			// 					data:[]
			// 				},{
			// 					name:this.$t('i18nn_b14b20e17d756cb2'),
			// 					data:[]
			// 				},{
			// 					name:this.$t('i18nn_cce168301447d1ba'),
			// 					data:[]
			// 				}];

			// 				tableData.forEach(item=>{
			// 					if('60'==item.status){
			// 						allTabData[0].data.push(item);
			// 					} else if('70'==item.status){
			// 						allTabData[1].data.push(item);
			// 					} else if(['30','35','40','50','55'].includes(item.status)){
			// 						allTabData[2].data.push(item);
			// 					}
			// 				});

			// 				this.allTabData = allTabData;
			// 				// let successData = [];
			// 				// let failData = [];
			// 				// tableData.forEach(item=>{
			// 				// 	if(!!item.relationNo){
			// 				// 		successData.push(item);
			// 				// 	} else {
			// 				// 		failData.push(item);
			// 				// 	}
			// 				// });
			// 				// this.successData = successData;
			// 				// this.failData = failData;
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading_load = false;
			// 			this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>