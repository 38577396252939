<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_0d81640498b8d063')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="0"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_9f88e3e6b1e719bd')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1000px">
		<div v-loading="loading">
			<!-- <el-radio-group v-model="PrtOrderExpressData">
				<el-radio :label="item.code" border :disabled="0 == item.isUse" v-for="(item, index) in PrtOrderExpressList" :key="index">
					{{ item.logistics }}({{ item.codeName }})
					<span v-if="0 == item.isUse" style="color: red;">X</span>
				</el-radio>
			</el-radio-group> -->


			<!-- <el-card v-for="(item, index) in PrtOrderExpressList" :key="index" style="margin-bottom: 10px;" :body-style="{ padding: '0px' }" shadow="never">
				<div slot="header" class="clearfix">
					<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse">
						<strong>{{ item.logistics }}({{ item.codeName }})</strong>
						<span v-if="0 == item.isUse" style="color: red;">X不可用</span>
					</el-radio>
				</div>
				<el-descriptions border size="mini" v-if="item.descJson" :labelStyle="{ 'background': '#ecf5ff'}">
					<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson" :key="index2">{{ item2.value }}</el-descriptions-item>
				</el-descriptions>
			</el-card> -->

			<el-card v-for="(item, index) in PrtOrderExpressList" :key="index" :body-style="{ padding: '10px' }"
				shadow="never">
				<!-- <div slot="header" class=""> -->
				<div style="display:flex;align-items: center;">
					<div class="">
						<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse"
							@change="selLgsAction">
							<strong>{{ item.logistics }}({{ item.codeName }})</strong>
							<span v-if="0 == item.isUse" style="color: red;">X<span>{{$t('i18nn_ab5902ee5c645e01')}}</span></span>
						</el-radio>
					</div>
					<div v-if="item.descJson && item.descJson.length>0">
						<el-descriptions border size="mini">
							<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson"
								:key="index2">{{ item2.value }}</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
			</el-card>
			
			<!-- 限制发货地址 -->
			<el-card :title="$t('i18nn_2bfaf322a81c4e8c')" style="">
				<div slot="header" class=""><span>{{$t('i18nn_f1e789e4700e15e3')}}</span></div>
				<div style="">
					<el-radio-group v-model="selCopeShip" @change="ChangCopeShip">
						<div>
							<el-radio :label="item.name" v-for="(item,index) in CopeShipfromList"
								:key="index">{{item.name}}</el-radio>
						</div>
					</el-radio-group>
					<div v-if="form.senderVo && selCopeShip" style="line-height: 200%; font-size: 14px; color:#E6A23C;">
						<span>{{form.senderVo.firstname}},</span>
						<span v-if="form.senderVo.lastname">{{form.senderVo.lastname}},</span>
						<span>({{form.senderVo.mobile}}),</span>
						<span v-if="form.senderVo.mail">({{form.senderVo.mail}}),</span>
						<span v-if="form.senderVo.company">{{form.senderVo.company}},</span>
						<span>{{form.senderVo.addr1}},</span>
						<span v-if="form.senderVo.addr2">{{form.senderVo.addr2}},</span>
						<span>{{form.senderVo.city}},</span>
						<span>{{form.senderVo.province}},</span>
						<span>{{form.senderVo.postcode}},</span>
						<span>{{form.senderVo.country}}</span>
					</div>
				</div>
				
				<!-- <div style="margin: 5px 0;">
					<el-checkbox v-model="isComparePrice">自定义发件人地址(仅比价时可自定义)</el-checkbox>
				</div> -->
			</el-card>

			<!-- <div style="padding: 10px;">
				<el-collapse>
					<el-collapse-item :title="item.codeName" :name="item.code" :disabled="0 == item.isUse" v-for="(item, index) in PrtOrderExpressList" :key="index">
						<template slot="title">
									<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse" @change="selLgsAction">
										<strong>{{ item.logistics }}({{ item.codeName }})</strong>
										<span v-if="0 == item.isUse" style="color: red;">X<span>{{$t('i18nn_ab5902ee5c645e01')}}</span></span>
									</el-radio>
									<el-button icon="el-icon-arrow-down" size="small" round :disabled="0 == item.isUse"></el-button>
						</template>
						<div>
							<el-descriptions border size="mini" v-if="item.descJson">
								<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson" :key="index2">{{ item2.value }}</el-descriptions-item>
							</el-descriptions>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div> -->

			<el-card style="margin-bottom: 10px;">
				<div slot="header"><strong>{{$t('i18nn_ecc3d56be2ae0baf')}}</strong></div>
				<div style="padding: 10px;display: flex;flex-wrap: wrap;">
					<div style="margin: 10px;">
						<span>{{$t('i18nn_7b16a04efec1728b')}}</span>：
						<el-input-number v-model="insuranceValue" controls-position="right"></el-input-number>
					</div>
					<div style="margin: 10px;">
						<span>{{$t('i18nn_7e80cfa637ec486b')}}</span>：
						<el-select filterable clearable v-model="signatureService" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 200px;">
							<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index" :label="item.name"
								:value="item.key">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ item.name }}</span>
									<span class="sel_option_code" style="">{{ item.key }}</span>
								</div>
							</el-option>
						</el-select>
					</div>
					<div style="margin: 10px;">
						<span>{{$t('i18nn_e716ca411b2142e3')}}</span>：
						<el-select filterable clearable v-model="channelsService" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 200px;">
							<el-option v-for="(item,index) in selectOption.wh_channels" :key="index" :label="item.name"
								:value="item.key">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ item.name }}</span>
									<span class="sel_option_code" style="">{{ item.key }}</span>
								</div>
							</el-option>
						</el-select>
					</div>

				</div>
			</el-card>
			<!-- <div v-if="'1' == PrtOrderExpressData" style="color:red; padding: 10px;">
				批量打单支持的服务级别为【FedEx Home Delivery】，如果打单失败，可以进行单条打单，选择适用的服务级别.
			</div> -->
		</div>
		<div class="drawer-footer">
			<!-- <el-button type="primary" plain @click="dialogPrtOrderExpress = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button> -->
			<el-button type="primary" @click="batchSubmitPrintOrder()">{{ $t('i18nn_ad4345dbaabe1479') }}</el-button>
		</div>
	</el-drawer>
	<!-- </el-dialog> -->

</template>
<script>
	export default {
		props: {
			openTime: {},
			rows: {},
		},
		components: {},
		data() {
			return {
				dialogFile: false,

				//选择批量打单 快递公司
				dialogPrtOrderExpress: false,
				PrtOrderExpressData: '',
				PrtOrderExpressList: [],
				insuranceValue: "", //  保险
				signatureService: "", // 签名
				channelsService: "", //渠道编码
				
				carrier:"",

				multipleSelection: [],
				
				//限制地址
				CopeShipfromList: [],
				selCopeShip: '',
				form:{
					senderVo: {
						firstname: '',
						lastname: '',
						company: '',
						addr1: '',
						addr2: '',
						city: '',
						province: '',
						postcode: '',
						country: '',
						mobile: '',
						mail: ''
					},
				},

				// loadingUpload: false,
				loading: false,
				// filterData: {
				// 	id: ''
				// },
				// loading_load: false,
				// tableData: [],
				selectOption: {
					wh_signature_service:[],
					wh_channels:[],
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			//数据字典
			// getDicData(['wh_signature_service'],
			// 	(data) => {
			// 		this.selectOption.wh_dic_signature_service = data['wh_signature_service'];
			// 	});
		},
		methods: {
			initData() {
				
				this.insuranceValue = ""; //  保险
				this.signatureService = ""; // 签名
				this.channelsService = "";//渠道
				this.carrier = "";//渠道
				this.PrtOrderExpressData = "";
				this.PrtOrderExpressList = [];
				
				this.selCopeShip ="";
				this.CopeShipfromList = [];
				this.form.senderVo = null;
				
				this.multipleSelection = this.rows;
				
				// this.tableData = [];
				// this.getListData();
				this.getPriterOrderUps2LgsList();
			},
			
			//选择渠道
			selLgsAction() {
				let selLgsData = this.PrtOrderExpressList.find(item => {
					return this.PrtOrderExpressData == item.code;
				});
				//签名
				if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
					this.selectOption.wh_signature_service = selLgsData.extendParamJson.signatures;
				} else {
					this.selectOption.wh_signature_service = [];
				}
				this.signatureService = null;
				//渠道编码
				if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
					this.selectOption.wh_channels = selLgsData.extendParamJson.channels;
					if (this.selectOption.wh_channels && this.selectOption.wh_channels.length > 0) {
						this.channelsService = this.selectOption.wh_channels[0].key;
					}
				} else {
					this.selectOption.wh_channels = [];
				}
				
				//渠道
				if(selLgsData.logistics){
					this.carrier = selLgsData.logistics;
				} else {
					this.carrier = "";
				}
				
				//限制地址
				this.selCopeShip ="";
				this.CopeShipfromList = [];
				this.form.senderVo = null;
				
				//限制地址
				this.getCopeShipFrom(this.PrtOrderExpressData);
			},
			
			//cope改变
			ChangCopeShip(v) {
				this.form.senderVo = null;
				// this.form.senderVo.firstname = "";
				// // this.form.senderVo.company = ship.name;
				// // if (shipAddr.addresses && shipAddr.addresses1) {
				// this.form.senderVo.addr1 = "";
				// // }
				// // if (shipAddr.addresses2) {
				// 	this.form.senderVo.addr2 = "";
				// // }
				// this.form.senderVo.city = "";
				// this.form.senderVo.province = "";
				// this.form.senderVo.postcode = "";
				// this.form.senderVo.country = "";
				// this.form.senderVo.mobile = "";
				// // this.form.senderVo.uuid = shipAddr.uuid;
				
				let ship = this.CopeShipfromList.find(item => {
					return item.name == v;
				});
				console.log('ship', ship);
				let shipAddr = ship.address;
			
				if (shipAddr) {
					this.form.senderVo = {};
					this.form.senderVo.firstname = shipAddr.name;
					// this.form.senderVo.company = ship.name;
					// if (shipAddr.addresses && shipAddr.addresses1) {
					this.form.senderVo.addr1 = shipAddr.addresses1;
					// }
					if (shipAddr.addresses2) {
						this.form.senderVo.addr2 = shipAddr.addresses2;
					}
					this.form.senderVo.city = shipAddr.city;
					this.form.senderVo.province = shipAddr.provinceCode;
					this.form.senderVo.postcode = shipAddr.postalCode;
					this.form.senderVo.country = shipAddr.countryCode;
					this.form.senderVo.mobile = shipAddr.phone;
					// this.form.senderVo.uuid = shipAddr.uuid;
				} else {
					this.form.senderVo = null;
				}
				
				// this.queryLgsPrtOrderAction();
			
			},
			//旧-->biz_wh_lgskey_cope_fedex 发货地址
			//新--> 'cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key
			//限制发货地址
			getCopeShipFrom(lgsCode) {
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhPriterOrderCopeShipfrom + "?lgsCode=" + lgsCode, {})
					.then(({
						data
					}) => {
						// console.log('查询cope_fedex，请求成功');
						// console.log(data);
						this.loading = false;
						if (200 == data.code) {
							this.CopeShipfromList = data.rows;
							if (this.CopeShipfromList[0] && this.CopeShipfromList[0].name) {
								this.selCopeShip = this.CopeShipfromList[0].name;
								this.ChangCopeShip(this.selCopeShip);
							}
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.errorData');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						this.loading = false;
						// console.log('查询cope_fedex接口，请求失败');
						this.$message.error(this.$t('tips.requestErrorData'));
					});
			},

			//批量提交打单
			batchSubmitPrintOrder() {
				// event.stopPropagation();
				// let parm = [row.id];
				let parm = [];

				// if (row) {
				// 	//     //单条
				// 	parm = [row.id];
				// } else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				// if (dataList.findIndex(item => '10' != item.exprStatus) > -1) {
				// 	this.$message.warning(this.$t('i18nn_7b23bac6ffe353b9'));
				// 	return;
				// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// }

				let confirm_msg = '';
				let postUrl = '';
				let postDataParm = {
					exprIds: parm
				};
				//不同打单渠道
				// if ('1' == this.PrtOrderExpressData) {
				// 	confirm_msg = '确定提交 Fedex 打单' + parm.length + '条数据吗?';
				// 	postUrl = this.$urlConfig.WhPriterOrderBatchCreate;
				// } else if ('2' == this.PrtOrderExpressData) {
				// 	confirm_msg = '确定提交 Ups(渠道1) 打单' + parm.length + '条数据吗?';
				// 	postUrl = this.$urlConfig.WhPriterOrderUpsBatchCreate;
				// } else if ('3' == this.PrtOrderExpressData) {
				// 	confirm_msg = '确定提交 Ups(渠道2) 打单' + parm.length + '条数据吗?';
				// 	postUrl = this.$urlConfig.WhPriterOrderUps2BatchCreate;
				// 	postDataParm = { ids: parm };
				// } else {
				// confirm_msg = this.$t('i18nn_04c1c1452cb3359f') + parm.length + '条数据吗?';
				confirm_msg = this.$t('tips.confirmSubmit');
				postUrl = this.$urlConfig.WhPriterOrderUps2BatchCreate;
				postDataParm = {
					ids: parm,
					lgsKeyCode: this.PrtOrderExpressData,
					insuranceValue: this.insuranceValue ? this.insuranceValue : null, //  保险
					signatureService: this.signatureService ? this.signatureService : null, // 签名
					channel: this.channelsService ? this.channelsService : null, // 渠道
					
					carrier: this.carrier ? this.carrier : null,
					senderVo: this.form.senderVo ? this.form.senderVo : null,
					
				};
				// }
				//提交数据
				if (postUrl) {
					this.$confirm(confirm_msg, this.$t('i18nn_daaaeb1b7b22b126'), {
							dangerouslyUseHTMLString: true,
							type: 'warning'
						})
						.then(() => {
							this.postData(postUrl, postDataParm, '', () => {
								// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								// 	type: 'success'
								// });
								this.dialogFile  = false;
								this.$message.success(this.$t('tips.submitSuccess'));
								this.$emit('success')
							});
						})
						.catch(() => {});
				}

				// if ('1' == this.PrtOrderExpressData) {
				// 	this.$confirm(
				// 		'批量打单支持的服务级别为【FedEx Home Delivery】，如果打单失败，可以进行单条打单，选择适用的服务级别.<br />确定提交打单' + parm.length + '条数据吗?',
				// 		this.$t('i18nn_daaaeb1b7b22b126'),
				// 		{
				// 			dangerouslyUseHTMLString: true,
				// 			type: 'warning'
				// 		}
				// 	)
				// 		.then(() => {
				// 			this.postData(this.$urlConfig.WhPriterOrderBatchCreate, { exprIds: parm }, () => {
				// 				this.dialogPrtOrderExpress = false;
				// 				this.initData();
				// 				this.$message.success(this.$t('9f30371831a98237'));
				// 			});
				// 		})
				// 		.catch(() => {});
				// } else if ('2' == this.PrtOrderExpressData) {
				// 	this.$confirm('确定提交 Ups(渠道1) 打单' + parm.length + '条数据吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
				// 		dangerouslyUseHTMLString: true,
				// 		type: 'warning'
				// 	})
				// 		.then(() => {
				// 			this.postData(this.$urlConfig.WhPriterOrderUpsBatchCreate, { exprIds: parm }, () => {
				// 				this.dialogPrtOrderExpress = false;
				// 				this.initData();
				// 				this.$message.success(this.$t('9f30371831a98237'));
				// 			});
				// 		})
				// 		.catch(() => {});
				// } else if ('3' == this.PrtOrderExpressData) {
				// 	this.$confirm('确定提交 Ups(渠道2) 打单' + parm.length + '条数据吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
				// 		dangerouslyUseHTMLString: true,
				// 		type: 'warning'
				// 	})
				// 		.then(() => {
				// 			this.postData(this.$urlConfig.WhPriterOrderUps2BatchCreate, { exprIds: parm }, () => {
				// 				this.dialogPrtOrderExpress = false;
				// 				this.initData();
				// 				this.$message.success(this.$t('9f30371831a98237'));
				// 			});
				// 		})
				// 		.catch(() => {});
				// }
			},

			//usp 渠道2-lgs列表
			getPriterOrderUps2LgsList() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs列表信息');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.PrtOrderExpressList = data.rows;
							// if (this.PrtOrderExpressList.length > 0) {
							// 	if(0 != this.PrtOrderExpressList[0].isUse){
							// 		this.PrtOrderExpressData = this.PrtOrderExpressList[0].code;
							// 	}
							// }

							// callback(data.rows);
							// this.PriterOrderRate3 = [data.data];
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.queryErrorFailed');
							}
							this.$message.warning(data.msg);
							// this.PriterOrderRate3_err_msg = data.msg;
						}
					})
					.catch(error => {
						console.log(error);
						console.log('lgs列表信息接口，请求失败');
						this.loading = false;
						this.$message.error(this.$t('tips.queryRequestFailed'));
						// this.PriterOrderRate3_err_msg = '查询信息失败,请重试!';
					});
			},

			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					// console.log(this.$t('i18nn_bc868e024b80d2e3'));
					// console.log(data);

					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.dialogWrTracking = false;
						// // this.dialogPrtOrderExpress = false;
						// this.getPageData();
						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						callback()
						// } else {
						// 	this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 		type: 'success'
						// 	});
						// }
						// if('function'==typeof callback ){
						// 	callback();
						// }

					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>