<template>
	<!-- <el-dialog :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1200px">

		<div class="">
			<el-tabs v-model="detTabActiveName" type="border-card">
				<el-tab-pane label="" name="first" v-loading="loading">
					<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>
					<!--本页切换列表-->
					<!-- <div> -->
					<div class="" v-loading="loading_load" element-loading-text="加载中...">
						<!-- <div class=""> -->
						<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>{{$t('i18nn_92f2ebbe14794f38')}}</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">


								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

						<!-- <div>
						<div class="filterCon" style="">
						</div>
					</div> -->

						<!-- <div> -->
						<el-card shadow="never" style="margin-bottom: 10px;">
							<el-descriptions :title="''">
								<el-descriptions-item
									:label="$t('i18nn_37181c74559db182')">{{tableData.orderNumber}}</el-descriptions-item>

								<!-- <el-descriptions-item :label="$t('i18nn_148c3451c7861e8f')">{{tableData.batchNo}}</el-descriptions-item> -->
								<!-- <el-descriptions-item :label="$t('i18nn_ce764b74be1b9a90')">{{tableData.workNo}}</el-descriptions-item> -->
								<el-descriptions-item
									:label="$t('i18nn_83b1b50eb00a9fb6')">{{tableData.thirdOrderNumber}}</el-descriptions-item>
								<!-- <el-descriptions-item :label="$t('i18nn_18d7892498938435')">{{tableData.cusName}}</el-descriptions-item> -->
								<el-descriptions-item :label="$t('i18nn_6cdece641436d7ab')">
									<!-- <el-tag type=""> -->
									<!-- {{ tableData.exprStatusName }} -->
									{{ $Utils.i18nKeyText(tableData,'exprStatusName') }}
									<!-- </el-tag> -->
								</el-descriptions-item>
								
								<el-descriptions-item
									:label="$t('i18nn_f6283c46ba925ab4')">{{tableData.senderAddr}}</el-descriptions-item>
									
								<el-descriptions-item
									:label="$t('i18nn_43a3586339251494')">{{tableData.commitDate}}</el-descriptions-item>
								<el-descriptions-item
									:label="$t('i18nn_d99d790ec4383bfb')">{{tableData.exprStatusTime}}</el-descriptions-item>
							</el-descriptions>
						</el-card>

						<el-card shadow="never" style="margin-bottom: 10px;" :body-style="{'padding':'0'}">
							<div slot="header" class="">
								<h3>{{$t('i18nn_71a647a484c3f5c2')}}</h3>
							</div>
							<el-table v-loading="loading" ref="multipleTable2" :data="tableData.expressSkuList" stripe :border="true"
								style="width: 100%" size="small">
								<!-- <el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')" width="80"></el-table-column> -->
								<el-table-column prop="goodsSku" label="SKU">
									<template slot-scope="scope2">
										<div>
											<div>
												{{scope2.row.goodsSku}}
											</div>
											<div v-if="scope2.row.mySkuDto">
												<div><span>名称</span>:<span>{{ scope2.row.mySkuDto.goodsName }}</span></div>
												<div v-if="scope2.row.mySkuDto.goodsName!=scope2.row.mySkuDto.goodsNameEn">
													{{ scope2.row.mySkuDto.goodsNameEn }}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="goodsSku" label="SKU"></el-table-column>
								<el-table-column prop="goodsSku" label="SKU名称" min-width="100">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>{{ scope2.row.mySkuDto.goodsName }}</div>
											<div v-if="scope2.row.mySkuDto.goodsName!=scope2.row.mySkuDto.goodsNameEn">
												{{ scope2.row.mySkuDto.goodsNameEn }}
											</div>
										</div>
									</template>
								</el-table-column> -->
								<el-table-column prop="quantity" :label="$t('i18nn_fc7e87b5dfa6e4b8')" width="80">
								</el-table-column>
								
								<el-table-column prop="goodsSku" :label="'重量'+'(LB)'">
									<template slot-scope="scope2">
										<div>
											<div>
												<span>单件</span>:
												<span>{{scope2.row.singleWeight}}</span>
											</div>
											<div>
												<span>合计</span>:
												<span>{{scope2.row.totalWeight}}</span>
											</div>
										</div>
									</template>
								</el-table-column>
								
								<el-table-column prop="mySkuDto" :label="'SKU'+'规格'">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>{{ scope2.row.mySkuDto.sizeUnitName }}</div>
											<div>
												<!-- <div>
													<span>{{$t('0e60303b30d5d4b4')}}</span>*
													<span>{{$t('6cd31871f8528dd5')}}</span>*
													<span>{{$t('93c9ea4a0e52c641')}}</span>
												</div>: -->
												<span>{{ scope2.row.mySkuDto.goodsLength }}*{{ scope2.row.mySkuDto.goodsWidth }}*{{ scope2.row.mySkuDto.goodsHeight }}</span>
											</div>
											<div>
												<!-- <div>{{$t('96f1a4b17e75892d')}}</div>: -->
												<span>{{ scope2.row.mySkuDto.goodsWeight }}</span>
											</div>
										</div>
										
									</template>
								</el-table-column>

								<!-- <el-table-column prop="mySkuDto" :label="$t('i18nn_b6bf0a07fe26f74f')" min-width="50">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.sizeUnitName }}</div>
									</template>
								</el-table-column>

								<el-table-column prop="mySkuDto" min-width="80">
									<template slot="header">
										<div>
											<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>*<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>*<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>
										</div>
									</template>
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											{{ scope2.row.mySkuDto.goodsLength }}*{{ scope2.row.mySkuDto.goodsWidth }}*{{ scope2.row.mySkuDto.goodsHeight }}
										</div>
									</template>
								</el-table-column> -->

								<!-- <el-table-column prop="goodsWeight" :label="$t('i18nn_96f1a4b17e75892d')" min-width="50">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.goodsWeight }}</div>
									</template>
								</el-table-column> -->
								<el-table-column prop="mySkuDto" :label="'SKU'+'规格转换'">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>LB/INC</div>
											<div>
												<!-- <div>
													<span>{{$t('0e60303b30d5d4b4')}}</span>*
													<span>{{$t('6cd31871f8528dd5')}}</span>*
													<span>{{$t('93c9ea4a0e52c641')}}</span>
												</div>: -->
												<span>{{ scope2.row.mySkuDto.goodsINCLength }}*{{ scope2.row.mySkuDto.goodsINCWidth }}*{{ scope2.row.mySkuDto.goodsINCHeight }}</span>
											</div>
											<div>
												<!-- <div>{{$t('96f1a4b17e75892d')}}</div>: -->
												<span>{{ scope2.row.mySkuDto.goodsLBWeight }}</span>
											</div>
										</div>
										
									</template>
								</el-table-column>
								<!-- <el-table-column prop="mySkuDto" min-width="80">
									<template slot="header">
										<div>
											<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>/<span>{{$t('i18nn_d5d972e6063a3180')}}</span>(lbs/in)
										</div>
									</template>
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div v-if="1==scope2.row.mySkuDto.sizeUnit">
												<div>
													<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{$Utils.kgToLbsFixed2(scope2.row.mySkuDto.goodsWeight)}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsLength)}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsWidth)}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsHeight)}}</span>
												</div>
											</div>
										</div>
									</template>
								</el-table-column> -->

								<el-table-column prop="goodsSku" min-width="50">
									<template slot="header">
										<div>SKU<span>{{$t('i18nn_2674282277c3714e')}}</span></div>
									</template>
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>
												<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope2.row.goodsImg">
													<div slot="error" class="image-slot" style="font-size: 40px;"><i
															class="el-icon-picture-outline"></i></div>
												</el-image>
											</div>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</el-card>

						<div style="display: flex;margin-bottom: 10px;">
							<el-card shadow="never" style="margin-right: 10px;">
								<el-descriptions :title="$t('i18nn_aae671b49dde2bba')" :column="1">
									<!-- <el-descriptions-item :label="$t('i18nn_c944a6686d996ab3')">{{tableData.carrier}}</el-descriptions-item> -->
									<!-- <el-descriptions-item :label="$t('i18nn_abf9f4b8c93d641e')">{{tableData.orderNumber}}</el-descriptions-item> -->
									<el-descriptions-item
										:label="$t('i18nn_b0c50b6ff9674173')">{{tableData.carrier}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_581bc71c027386cb')">{{tableData.tracking}}</el-descriptions-item>


									<el-descriptions-item :label="$t('i18nn_d7315fb8114eb446')">{{tableData.rate}}</el-descriptions-item>
									<!-- <el-descriptions-item :label="$t('i18nn_b7a10fe4e9528362')">{{tableData.packingNo}}</el-descriptions-item> -->
									<el-descriptions-item :label="$t('i18nn_f6283c46ba925ab4')">{{tableData.senderAddr}}
									</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_b4296642bcc9f428')">{{tableData.returnAddr}}
									</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_1324a1d91ae3de53')">
										<a v-if="tableData.labelUrl" :href="tableData.labelUrl" :title="tableData.labelUrl" target="_blank">
											{{tableData.labelUrl}}
										</a>
									</el-descriptions-item>

									<el-descriptions-item
										:label="$t('i18nn_3601b5a98803f4d7')">{{tableData.isSelfLabelName}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_488e817afcd3b95e')">{{tableData.signatureService}}{{tableData.signatureServiceName}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_7b16a04efec1728b')">{{tableData.insuranceValue}}</el-descriptions-item>
								</el-descriptions>
							</el-card>

							<el-card shadow="never" style="">
								<el-descriptions :title="$t('i18nn_dfd180c9fc784ac6')" :column="1">
									<el-descriptions-item :label="$t('i18nn_e44e9a291d80c593')">{{tableData.name}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_c2d9cc88561f8cdc')">{{tableData.phone}}</el-descriptions-item>
									<el-descriptions-item label="Email">{{tableData.email}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_c3755bab459a2cbc')">{{tableData.company}}</el-descriptions-item>
									<el-descriptions-item>
										<template slot="label">
											<span><span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>1</span>
										</template>
										{{tableData.street1}}</el-descriptions-item>
									<el-descriptions-item>
										<template slot="label">
											<span><span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>2</span>
										</template>
										{{tableData.street2}}</el-descriptions-item>
									<el-descriptions-item>
										<template slot="label">
											<span><span>{{$t('i18nn_e05b5d049b64b040')}}</span>,<span>{{$t('i18nn_7cab79716236ebb0')}}</span>,<span>{{$t('i18nn_c4913ab43009b365')}}</span></span>
										</template>
										{{tableData.city}},{{tableData.state}},{{tableData.postalCode}}
									</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_b166e4e8fe9513fa')">{{tableData.country}}</el-descriptions-item>
									
									<el-descriptions-item>
										<template slot="label">
											<span><span>{{$t('i18nn_9461d15f7ba8ad99')}}</span></span>
										</template>
										{{tableData.name}},
										{{tableData.company}},<br />
										{{tableData.street1}},
										{{tableData.street2}},<br />
										{{tableData.city}},
										{{tableData.state}},
										{{tableData.postalCode}},<br />
										{{tableData.country}},<br />
										({{tableData.phone}}),
										({{tableData.email}})
									</el-descriptions-item>
										
								</el-descriptions>
							</el-card>
							<!-- </div> -->
							<!-- </div> -->

							<!-- <el-table v-loading="loading" ref="multipleTable" :data="[tableData]" stripe :border="true"
						:height="$store.state.tableMaxHeight6" style="width: 100%" size="small">
						<el-table-column type="expand" fixed="left">
							<template slot-scope="scope">
								<div style="margin-left: 50px;">
									<div
										v-if="scope.row && scope.row.expressSkuList && scope.row.expressSkuList.length > 0">
										<el-table :data="scope.row.expressSkuList" :border="true" style="width: 100%;"
											size="small">
											<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
												:label="$t('7b1c2b1adc920d9c')"></el-table-column>
											<el-table-column prop="goodsSku" label="SKU"></el-table-column>

											<el-table-column prop="whNo" label="undefined"></el-table-column>

											<el-table-column prop="quantity" label="undefined"
												min-width="50"></el-table-column>

											<el-table-column prop="goodsSku" label="SKU名称"
												min-width="100">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														<div>{{ scope2.row.mySkuDto.goodsName }}</div>
														<div>{{ scope2.row.mySkuDto.goodsNameEn }}</div>
													</div>
												</template>
											</el-table-column>

											<el-table-column prop="goodsSku" label="undefined"
												min-width="50">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														<div>
															<el-image lazy style="width: auto; height: 40px"
																fit="contain" :src="scope.row.goodsImg">
																<div slot="error" class="image-slot"
																	style="font-size: 40px;"><i
																		class="el-icon-picture-outline"></i></div>
															</el-image>
														</div>
													</div>
												</template>
											</el-table-column>

											<el-table-column prop="mySkuDto" label="undefined"
												min-width="50">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														{{ scope2.row.mySkuDto.sizeUnitName }}</div>
												</template>
											</el-table-column>

											<el-table-column prop="goodsWeight" label="undefined"
												min-width="50">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														{{ scope2.row.mySkuDto.goodsWeight }}</div>
												</template>
											</el-table-column>

											<el-table-column prop="mySkuDto" label="undefined"
												min-width="80">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														{{ scope2.row.mySkuDto.goodsLength }}*{{ scope2.row.mySkuDto.goodsWidth }}*{{ scope2.row.mySkuDto.goodsHeight }}
													</div>
												</template>
											</el-table-column>
											<el-table-column prop="mySkuDto" :label="'重量/长度计算(lbs/in)'" min-width="80">
												<template slot-scope="scope2">
													<div v-if="scope2.row.mySkuDto">
														<div v-if="1==scope2.row.mySkuDto.sizeUnit">
															<div>
																<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{$Utils.kgToLbsFixed2(scope2.row.mySkuDto.goodsWeight)}}</span>
															</div>
															<div>
																<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsLength)}}</span>
															</div>
															<div>
																<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsWidth)}}</span>
															</div>
															<div>
																<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{$Utils.cmToInFixed2(scope2.row.mySkuDto.goodsHeight)}}</span>
															</div>
														</div>
													</div>
												</template>
											</el-table-column>
											<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="220px"
												fixed="right">
											</el-table-column>
										</el-table>
									</div>
									<div v-else>{{ 'undefined' }}</div>

									<div>
										<el-table :data="[scope.row]" :border="true" style="width: 100%;" size="small">
											<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
												:label="$t('7b1c2b1adc920d9c')"></el-table-column>
											<el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')">
											</el-table-column>
											<el-table-column prop="name" label="undefined">
											</el-table-column>

											<el-table-column prop="company" label="undefined">
											</el-table-column>

											<el-table-column prop="phone" label="undefined">
											</el-table-column>

											<el-table-column prop="email" label="E-Mail"></el-table-column>
											<el-table-column prop="street1" label="undefined">
											</el-table-column>
											<el-table-column prop="street2" label="undefined">
											</el-table-column>
											<el-table-column prop="city" label="undefined"></el-table-column>
											<el-table-column prop="state" label="undefined">
											</el-table-column>
											<el-table-column prop="postalCode" label="undefined">
											</el-table-column>
											<el-table-column prop="country" label="undefined">
											</el-table-column>
											<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="220px"
												fixed="right">
											</el-table-column>
										</el-table>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>

						<el-table-column prop="statusName" label="undefined">
							<template slot-scope="scope">

								<el-tag type="" v-if="'0' == scope.row.exprStatus">{{ scope.row.exprStatusName }}
								</el-tag>
								<el-tag type="success" v-else-if="'10' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<el-tag type="warning" v-else-if="'20' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<el-tag type="danger" v-else-if="'30' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<el-tag type="success" v-else-if="'35' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<el-tag type="warning" v-else-if="'40' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<div v-else-if="'50' == scope.row.exprStatus">
									<el-tag type="danger">{{ scope.row.exprStatusName }}</el-tag>
									<el-link type="primary" icon="el-icon-right"
										@click="toExcepActionView($event, scope.row, scope.$index)">{{$t('i18nn_15da46e90a2c3e3b')}}</el-link>
								</div>
								<el-tag type="warning" v-else-if="'60' == scope.row.exprStatus">
									{{ scope.row.exprStatusName }}</el-tag>
								<el-tag type="info" v-else>{{ scope.row.exprStatusName }}</el-tag>
							</template>
						</el-table-column>

						<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column>
						<el-table-column prop="batchNo" :label="$t('i18nn_05e29fb623110c69')" width="100">
							<template slot-scope="scope">
								<div>
									<el-link v-if="scope.row.batchNo" type="danger" size="mini" icon="el-icon-right"
										@click="toActionView($event, scope.row, scope.$index)">
										{{ scope.row.batchNo }}
									</el-link>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>

						<el-table-column prop="commitDate" label="undefined"></el-table-column>

						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>

						<el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column>
						<el-table-column prop="tracking" label="undefined"></el-table-column>

						<el-table-column prop="labelUrl" :label="$t('i18nn_1324a1d91ae3de53')">
							<template slot-scope="scope">
								<div class="over_ellipsis" style="max-width: 100%;">
									<a v-if="scope.row.labelInfoDto" :href="scope.row.labelInfoDto.labelUrl"
										:title="scope.row.labelInfoDto.labelUrl" target="_blank">
										{{ scope.row.labelInfoDto.labelUrl }}
									</a>
								</div>
								<div class="over_ellipsis" style="max-width: 100%;">
									<a v-if="scope.row.labelUrl" :href="scope.row.labelUrl" :title="scope.row.labelUrl"
										target="_blank">{{ scope.row.labelUrl }}</a>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="rate" label="undefined"></el-table-column>

						<el-table-column prop="senderAddr" label="undefined"></el-table-column>
						<el-table-column prop="returnAddr" label="undefined"></el-table-column>

						<el-table-column prop="packingNo" :label="$t('i18nn_b7a10fe4e9528362')"></el-table-column>

						<el-table-column prop="attachments" label="undefined">
							<template slot-scope="scope">
								<div @click="openUploadFile($event, scope.row)">
									<div v-if="scope.row.attachments">
										<el-link type="primary">
											...
											<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
											<span>{{ scope.row.attachments.length }}</span>
											<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
										</el-link>
									</div>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column>

						<el-table-column prop="exprStatusTime" :label="$t('i18nn_d99d790ec4383bfb')"></el-table-column>

						<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>


					</el-table> -->
							<!-- </div> -->
							<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="" name="second">
					<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

					<FeeModelById :id="row.id"></FeeModelById>
				</el-tab-pane>
			</el-tabs>

		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';

	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			FeeModelById
		},
		data() {
			return {
				detTabActiveName: 'first',

				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				// loading_det_load: false,

				// loading_count: false,
				// countData: {},

				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				selectOption: {
					wh_no: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '',
					// exprStatus: '',
					orderNumber: '',
					// orderNumberList: [],
					// orderNumberListStr: '',
					// // cusNo: '',
					// userId: '',
					// commitDate: '',
					// commitDateArr: [],
					// isLabelUrl: 1,
					// carrier: '',
					// keyword: '',
					// senderAddr: '',
					// returnAddr: '',
					// batchNo: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log('WhDropShipping activated');

			// this.clearFilter();
			// if (this.$route.query && this.$route.query.orderNumber) {
			// 	this.filterData.orderNumber = this.$route.query.orderNumber;
			// }

			// this.initData();
		},
		//创建时
		created() {
			console.log('WhDropShipping created');
			// this.clearFilter();
			// if (this.$route.query && this.$route.query.state) {
			// 	this.filterData.exprStatus = this.$route.query.state;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				// 	this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
			},

			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// this.getPageData();
				this.tableData = this.row;

				this.$nextTick(() => {
					this.$refs.multipleTable2.doLayout();
				});
				// this.getDicData();
			},
			//分页的筛选项数据
			// pageFilterData() {
			// 	// let commitDateArr = this.filterData.commitDateArr;
			// 	// let startCommitDate = '';
			// 	// let endCommitDate = '';
			// 	// if (commitDateArr && commitDateArr.length == 2) {
			// 	// 	startCommitDate = commitDateArr[0];
			// 	// 	endCommitDate = commitDateArr[1];
			// 	// }
			// 	// let orderNumberList = [];

			// 	// if (this.filterData.orderNumberListStr) {
			// 	// 	orderNumberList = this.filterData.orderNumberList;
			// 	// } else if (this.filterData.orderNumber) {
			// 	// 	orderNumberList = [this.filterData.orderNumber];
			// 	// }

			// 	return {
			// 		// workNo: this.filterData.workNo ? this.filterData.workNo : null,
			// 		// batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
			// 		// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
			// 		orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
			// 		// orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
			// 		// // cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// // commitDate: this.filterData.commitDate ? this.filterData.commitDate : null,

			// 		// startCommitDate: startCommitDate ? startCommitDate : null,
			// 		// endCommitDate: endCommitDate ? endCommitDate : null,

			// 		// keyword: this.filterData.keyword ? this.filterData.keyword : null,
			// 		// carrier: this.filterData.carrier ? this.filterData.carrier : null,
			// 		// isLabelUrl: this.filterData.isLabelUrl ? this.filterData.isLabelUrl : null,

			// 		// senderAddr: this.filterData.senderAddr ? this.filterData.senderAddr : null,
			// 		// returnAddr: this.filterData.returnAddr ? this.filterData.returnAddr : null
			// 		// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			// 	};
			// },

			// //请求分页数据
			// getPageData() {
			// 	// let _this = this;

			// 	let filterData = Object.assign({
			// 			offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 			limit: this.pagination.page_size //当前页显示数目
			// 		},
			// 		this.pageFilterData()
			// 	);

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDropShippingPageList, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows && data.rows[0]) {
			// 				//表格显示数据
			// 				this.tableData = data.rows[0];
			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);
			// 				//当前页数
			// 				// this.pagination.current_page = parseInt(data.current);
			// 				//当前页条数
			// 				// this.pagination.page_size = parseInt(data.size);
			// 				// this.$message.success('商家账单列表，请求成功');
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code', 'wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>