<template>
	<div v-loading="loadingUpload">
	<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_ebd257df8f7efc53')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1000px" v-loading="loadingUpload"> -->
	
		<div style="width: 800px; margin: 0 auto;">
			<el-steps :active="stepActive">
				<el-step :title="$t('i18nn_bd6d1ded173dc477')" :description="'文件名匹配'+('1'==matchType?'运单号':'订单号')"></el-step>
				<el-step :title="$t('i18nn_a363e5f23b1040fe')"></el-step>
				<el-step :title="$t('i18nn_b93f7c8c4e2346f8')"></el-step>
			</el-steps>
		</div>


		<div style="margin-top: 10px;" v-if="0==stepActive">
			<div style="padding: 10px 0;  line-height: 180%;">
				<el-alert type="warning">
					<div slot="title" style="font-size: 16px; ">{{$t('i18nn_daaaeb1b7b22b126')}}</div>
					<div style="font-size: 14px; ">
						<div>{{$t('FormMsg.Select_long_whLabelFileUpload_1')}}</div>
						<div>2、{{'1'==matchType?'运单号':'订单号'}}<span>和文件名要相同才可以匹配</span></div>
					</div>
				</el-alert>
			</div>
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-upload"></i>
						<span>{{$t('i18nn_bd6d1ded173dc477')}}</span>
					</h3>
				</div>
				<!-- <div style="padding: 10px 0;"> -->
				<!-- <el-radio-group v-model="wh_file_type" size="medium">
					  <el-radio v-for="item in selectOption.wh_file_type" :key="item.code" :label="item.code">
							{{ $Utils.i18nCodeText(item) }}
						</el-radio>
					</el-radio-group> -->

				<!-- <el-select filterable clearable size="mini" v-model="form.carTmsType" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
					  <el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select> -->
				<!-- </div> -->
				
				<HyElWhUpLoad ref="HyElWhUpLoad" :listType="'text'" :watchKey="fileWatchKey" :fileListShow="fileList"
					:fileId="fileId" :folder="'storage/label/'" :fileKey="fileKey" :bucket="'hytx-oss-wh'"
					:accept="'application/pdf'" :btnText="$t('i18nn_e11679a643689255')" :maxSizeMB="5" :limit="1000"
					v-on:UploadSuccessUrl="FileUploadSuccessUrl"></HyElWhUpLoad>

			</el-card>
			<div style="text-align: center; margin-top: 10px;">
				<el-button type="warning" @click="submitExcelFormAction()" icon="el-icon-bottom">
					<span>{{$t('i18nn_4639f3a6e07c00b3')}}</span>(<span>{{$t('i18nn_5fefbf45c9ca6464')}}</span>)</el-button>
			</div>
		</div>

		<div style="margin-top: 10px;" v-else-if="1==stepActive">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-tickets"></i>
						<span>{{$t('i18nn_ac71ea3f789a778c')}}</span>
					</h3>
				</div>
				<div>
					<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
						size="small" :max-height="$store.state.frameConHeightWh3">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="matchingStatusName" :label="$t('i18nn_582446272516f554')" width="100">

							<template slot-scope="scope">
								<div>
									<el-tag type="success" v-if="'1'==scope.row.matchingStatus">
										{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
									<el-tag type="danger" v-else-if="'0'==scope.row.matchingStatus">
										{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
									<el-tag type="danger" v-else>{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
								</div>

							</template>
						</el-table-column>

						<el-table-column prop="orderNumber" :label="'面单文件名('+('1'==matchType?'运单号':'订单号')+')'"></el-table-column>

						<el-table-column prop="fileUrl" :label="$t('i18nn_d54012286fece209')">
							<template slot-scope="scope">
								<a :href="scope.row.fileUrl" target="_blank">{{ scope.row.fileUrl }}</a>
							</template>
						</el-table-column>
						<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right" align="left">
		          <template slot-scope="scope">
		            <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
		          </template>
		        </el-table-column> -->
					</el-table>
				</div>
			</el-card>
			<div style="text-align: center; margin-top: 10px;">
				<el-button type="warning" plain @click="reUploadAction()" icon="el-icon-top">
					<span>{{$t('i18nn_70e058bc23d52c1e')}}</span>(<span>{{$t('i18nn_aaf7f7f3df61a6a5')}}</span>)</el-button>
				<el-button type="primary" @click="submitSaveFormAction()" icon="el-icon-bottom">
					<span>{{$t('i18nn_4639f3a6e07c00b3')}}</span>(<span>{{$t('i18nn_a93e095e532cd424')}}</span>)</el-button>
			</div>
		</div>
		<div v-else-if="2==stepActive">
			<div style="font-size: 24px;line-height: 200%; margin: 20px; text-align: center;">
				<div>
					<i class="el-icon-circle-check"></i>
					<span>{{$t('i18nn_f9104346c336651d')}}</span>~
				</div>
				<!-- <div>
					您可以去 ' 一件代发 ' 模块列表查看 面单pdf文件~
				</div> -->
				<!-- <el-button type="warning" plain @click="$router.push({name:'WarehouseDropShipping'})" icon="el-icon-right">{{$t('i18nn_658d7f0cc43ab866')}}</el-button> -->
				<el-button type="primary" @click="closeAction()">{{$t('i18nn_8f2e8ec212959586')}}</el-button>
			</div>

		</div>

		<!-- <div slot="footer" class="dialog-footer">
      <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
    </div> -->
	<!-- </el-drawer> -->
	<!-- </el-dialog> -->
	</div>
</template>
<script>
	import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			// fileId: {
			//     default: function() {
			//       return this.$store.getters.getUserInfo.id;
			//     },
			//     type: String
			//   },
			fileKey: {
				default: function() {
					return 'label';
				},
				type: String
			},
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			matchType:{
				
			}
			// workNo: {
			//   default: function() {
			//     return '';
			//   },
			//   type: String
			// }
		},
		components: {
			HyElWhUpLoad
		},
		data() {
			return {
				stepActive: 0,
				// dialogFile: false,
				loadingUpload: false,
				//附件
				fileWatchKey: '1',
				fileList: [],
				fileId: this.$store.getters.getUserInfo.id,

				// wh_file_type:"",

				saveErr: false,

				listUrl: [],

				tableData: [],
				selectOption: {
					wh_file_type: []
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				// this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.stepActive = 0;
				//初始化上传控件
				this.listUrl = [];
				this.tableData = [];

				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.initData();
						this.$refs.HyElWhUpLoad.clearFileList();
					} catch (err) {
						console.log(err);
					}
				});
				// if (this.relationId) {
				//   this.getListData();
				// }
			},
			// changeFile(val){
			// 	console.log('changeFile', val);
			// 	// let listUrl = val.map(item => {
			// 	//   // "filePath":"","fileName"：“”
			// 	//   return {
			// 	//     filePath: 'https://www.hytx.com/',
			// 	//     fileName: item.name
			// 	//   };
			// 	// });
			// 	// this.verfiyPdfFile(listUrl);
			// },
			//提交-
			// verfiyPdfFile(formData) {
			//   this.loadingUpload = true;
			//   this.$http
			//     .put(this.$urlConfig.whMatchingLabel+'?workNo=' +  this.workNo, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//       console.log(data);
			//       this.loadingUpload = false;
			//       if (200 == data.code) {
			//         console.log('data',data);
			//       } else {
			//         this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			//           type: 'warning'
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//         // this.saveErr = true;
			//         // this.initData();
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log(this.$t('tips.submitError'));
			//       this.loadingUpload = false;
			//       // this.saveErr = true;
			//       this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//上传成功回调
			FileUploadSuccessUrl(val) {
				console.log('changeFileUrl', val);
				let listUrl = val.map(item => {
					// "filePath":"","fileName"：“”
					return {
						filePath: item.url,
						fileName: item.name
					};
				});
				console.log('listUrl', listUrl);
				// this.$alert('上传成功！共上传 ' + listUrl.length + ' 个附件', this.$t('tips.tipsTitle'), {
				//   type: 'success'
				//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// });
				this.listUrl = listUrl;

				// this.myForm.dataList = listUrl;
				// this.myForm.dataList = val;

				// this.submitExcelFormAction();
				this.submitExcelFormData();
			},
			//校验-
			submitExcelFormAction() {

				// if (this.saveErr) {
				// 	// 校验到后台有误时
				// 	this.submitExcelFormData();
				// } else {
					//   //重新执行-oss 文件上传及回调
					this.$nextTick(() => {
						try {
							this.$refs.HyElWhUpLoad.submitUpload();
						} catch (err) {
							console.log(err);
							//重新提交数据
							// this.submitExcelFormData();
						}
					});
				// }
			},
			//校验-
			submitExcelFormData() {
				let formData = [];
				this.listUrl.forEach(item => {
					if (item.fileName && item.filePath) {
						formData.push({
							// id: this.relationId,
							// wh_file_type:this.wh_file_type,
							fileName: item.fileName,
							url: item.filePath
						});
					}
				});
				this.submitExcelForm(formData);
			},
			//校验-
			submitExcelForm(formData) {
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.whMatchingLabel+'?matchType='+this.matchType, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.data) {
							// this.excelData = data.data.data;
							// this.dialogUploadVisible = false;

							// this.initData();
							// this.$alert(this.$t('i18nn_bc868e024b80d2e3'), this.$t('tips.tipsTitle'), {
							//   type: 'success',
							//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   callback: action => {
							//     // this.dialogFile = false;
							//     // this.initData();
							//     this.$emit('success');
							//   }
							// });
							this.tableData = data.rows;
							this.stepActive = 1;
							this.saveErr = false;
							// } else {
							//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
							//     type: 'warning'
							//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   });
							//   this.saveErr = true;
							//   // this.initData();
							// }
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							this.saveErr = true;
							// this.initData();
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//重新上传
			reUploadAction() {

				this.initData();
			},
			//保存
			submitSaveFormAction() {
				let formData = [];
				this.tableData.forEach(item => {
					if (item.id) {
						formData.push({
							"id": item.id, //"数据ID",
							"orderNumber": item.orderNumber, //this.$t('i18nn_ae61193590bd513d'),
							"fileUrl": item.fileUrl, //"label文件地址"
						});
					}
				});
				if (formData.length <= 0) {
					this.$message.warning(this.$t('tips.no_match_label'))
					return;
				}
				this.submitSaveForm(formData);
			},
			//保存
			submitSaveForm(formData) {
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.whSaveMatchingLabel+'?matchType='+this.matchType, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.data) {
							// this.excelData = data.data.data;
							// this.dialogUploadVisible = false;
							this.stepActive = 2;
							this.$message.success(this.$t('tips.submitSuccess'));
							this.$emit('success');
							// this.initData();
							// this.$alert(this.$t('i18nn_bc868e024b80d2e3'), this.$t('tips.tipsTitle'), {
							//   type: 'success',
							//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   callback: action => {
							//     // this.dialogFile = false;
							//     // this.initData();
							//     this.$emit('success');
							//   }
							// });
							// this.saveErr = false;
							// } else {
							//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
							//     type: 'warning'
							//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   });
							//   this.saveErr = true;
							//   // this.initData();
							// }
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							// this.saveErr = true;
							// this.initData();
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						// this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			closeAction(){
				this.$emit('close')
			},
			//删除
			// delAction(event, row) {
			//   event.stopPropagation();
			//   this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   })
			//     .then(() => {
			//       // this.$message({
			//       //   type: 'success',
			//       //   message: '删除成功!'
			//       // });
			//       this.delData(this.$urlConfig.WhFileUploadDel + '/' + row.id, row);
			//     })
			//     .catch(() => {
			//       // this.$message({
			//       //   type: 'info',
			//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//       // });
			//     });
			// },
			// //提交信息
			// delData(url, formData) {
			//   this.loadingUpload = true;
			//   this.$http
			//     .delete(url, {})
			//     .then(({ data }) => {
			//       console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//       console.log(data);
			//       this.loadingUpload = false;
			//       if (200 == data.code) {
			//         this.getListData();
			//       } else {
			//         this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			//           type: 'warning'
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log(this.$t('tips.submitError'));
			//       this.loadingUpload = false;
			//       this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//查询 /wh/bizWhFile/query/{relationId}
			getListData() {
				// this.loadingUpload = true;
				// this.$http
				//   .get(this.$urlConfig.WhFileUploadList + '/' + this.workNo, {})
				//   .then(({ data }) => {
				//     console.log(this.$t('i18nn_4195755cd53e871a'));
				//     console.log(data);
				//     this.loadingUpload = false;
				//     if (200 == data.code) {
				//       // if (data.rows) {
				//       this.tableData = data.rows;
				//       // } else {
				//       //   this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
				//       //     type: 'warning'
				//       //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				//       //   });
				//       // }
				//     } else {
				//       this.$alert('查询失败,请重试！', this.$t('tips.tipsTitle'), {
				//         type: 'warning'
				//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				//       });
				//     }
				//   })
				//   .catch(error => {
				//     console.log(error);
				//     console.log(this.$t('tips.submitError'));
				//     this.loadingUpload = false;
				//     this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
				//       type: 'warning'
				//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				//     });
				//   });
			},
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_file_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         this.selectOption.wh_file_type = data.data['wh_file_type'];
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t("tips.submitError");
			//         // }
			//         // this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       // this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
