<template>
	<!-- 导入excel -->
	<!-- <el-dialog :title="$t('i18nn_3c2605e54bb69e38')" append-to-body :close-on-click-modal="false"
		:visible.sync="dialogFile" width="1200px" top="0"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_826ff6f5d853fee5')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1200px">
		<div class="rate-card-list" style="padding: 10px;" v-loading="loading">
			<!-- <el-table :data="PriterOrderRate" stripe :border="true" style="width: 100%" size="small" v-loading="loading_rate_load"
		  @selection-change="handleSelectionChange2"
		  @row-click="handleCurrentChange2"> -->
			<div style="margin-bottom: 10px;">
				<span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>
				：
				<span>{{ selOrderRateData.orderNumber }}</span>
			</div>

			<el-card style="margin-bottom: 10px;">
				<div slot="header"><strong>{{$t('i18nn_9f88e3e6b1e719bd')}}</strong></div>
				<div style="padding: 10px;">
				
					<!-- <el-collapse>
						<el-collapse-item :title="item.codeName" :name="item.code" :disabled="0 == item.isUse"
							v-for="(item, index) in PrtOrderExpressList" :key="index">
							<template slot="title">
								<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse" @change="selLgsAction">
									<strong>{{ item.logistics }}({{ item.codeName }})</strong>
									<span v-if="0 == item.isUse" style="color: red;">X<span>{{$t('i18nn_ab5902ee5c645e01')}}</span></span>
								</el-radio>
								<el-button icon="el-icon-arrow-down" size="small" round :disabled="0 == item.isUse">
								</el-button>
							</template>
							<div>
								<el-descriptions border size="mini" v-if="item.descJson">
									<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson"
										:key="index2">{{ item2.value }}</el-descriptions-item>
								</el-descriptions>
							</div>
						</el-collapse-item>
					</el-collapse> -->
					
					<el-card v-for="(item, index) in PrtOrderExpressList" :key="index" :body-style="{ padding: '10px' }" shadow="never">
						<!-- <div slot="header" class=""> -->
						<div style="display:flex;align-items: center;">
							<div class="">
								<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse" @change="selLgsAction">
									<strong>{{ item.logistics }}({{ item.codeName }})</strong>
									<span v-if="0 == item.isUse" style="color: red;">X<span>{{$t('i18nn_ab5902ee5c645e01')}}</span></span>
								</el-radio>
							</div>
							<div v-if="item.descJson && item.descJson.length>0">
								<el-descriptions border size="mini">
									<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson" :key="index2">{{ item2.value }}</el-descriptions-item>
								</el-descriptions>
							</div>
						</div>
					</el-card>
				</div>
			</el-card>
			
			<!-- 限制发货地址 -->
			<el-card :title="$t('i18nn_2bfaf322a81c4e8c')" style="">
				<div slot="header" class=""><span>{{$t('i18nn_f1e789e4700e15e3')}}</span></div>
				<div style="padding: 20px;">
					<el-radio-group v-model="selCopeShip" @change="ChangCopeShip">
						<div>
							<el-radio :label="item.name" v-for="(item,index) in CopeShipfromList"
								:key="index">{{item.name}}</el-radio>
						</div>
					</el-radio-group>
					<div v-if="form.senderVo && selCopeShip" style="line-height: 200%; font-size: 14px; color:#E6A23C;">
						<span>{{form.senderVo.firstname}},</span>
						<span v-if="form.senderVo.lastname">{{form.senderVo.lastname}},</span>
						<span>({{form.senderVo.mobile}}),</span>
						<span v-if="form.senderVo.mail">({{form.senderVo.mail}}),</span>
						<span v-if="form.senderVo.company">{{form.senderVo.company}},</span>
						<span>{{form.senderVo.addr1}},</span>
						<span v-if="form.senderVo.addr2">{{form.senderVo.addr2}},</span>
						<span>{{form.senderVo.city}},</span>
						<span>{{form.senderVo.province}},</span>
						<span>{{form.senderVo.postcode}},</span>
						<span>{{form.senderVo.country}}</span>
					</div>
				</div>
				
				<!-- <div style="margin: 5px 0;">
					<el-checkbox v-model="isComparePrice">自定义发件人地址(仅比价时可自定义)</el-checkbox>
				</div> -->
			</el-card>
			<div>
				<el-card style="margin-bottom: 10px;">
					<div slot="header"><strong>{{$t('i18nn_ecc3d56be2ae0baf')}}</strong></div>
					<div style="padding: 10px;display: flex;flex-wrap: wrap;">
						<div style="margin: 10px;">
							<span>{{$t('i18nn_7b16a04efec1728b')}}</span>：
							<el-input-number v-model="insuranceValue" controls-position="right"></el-input-number>
						</div>
						<div style="margin: 10px;">
							<span>{{$t('i18nn_7e80cfa637ec486b')}}</span>：
							<el-select filterable clearable v-model="signatureService" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;" @change="queryLgsPrtOrderAction">
								<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index"
									:label="item.name" :value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
						<div style="margin: 10px;">
							<span>{{$t('i18nn_e716ca411b2142e3')}}</span>：
							<el-select filterable clearable v-model="channelsService" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;" @change="queryLgsPrtOrderAction">
								<el-option v-for="(item,index) in selectOption.wh_channels" :key="index"
									:label="item.name" :value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
					</div>
				</el-card>
			</div>
			<div>
				<el-button type="primary" @click="queryLgsPrtOrderAction()">{{$t('i18nn_9eefb7335988c499')}}</el-button>
			</div>
			<div>
				<el-table ref="multipleTable4" :data="tableData" highlight-current-row stripe :border="true"
					style="width: 100%" size="small">
					<el-table-column type="index" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>
					<el-table-column prop="carrier" :label="'Carrier'"></el-table-column>
					<el-table-column prop="carrierServiceLevel" :label="'CarrierServiceLevel'"></el-table-column>
					<el-table-column prop="channel" :label="'Channel'"></el-table-column>
					<el-table-column prop="price" :label="'Price'"></el-table-column>
					<el-table-column prop="" :label="'Details'" width="300">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDetail">
								<div v-for="(val, key, index) in scope.row.feeDetail" :key="key">
									<span>{{key}}</span>：<span>{{val}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('i18nn_93f5ca01b006206c')">
						<template slot-scope="scope">
							<div>
								<el-button @click="submitOrderRateAction($event, scope.row, scope.$index)" type="primary" size="small" icon="el-icon-right">
									{{ $t('i18nn_3d6502c491aca17a') }}
								</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>


			<!-- <el-card style="margin-bottom: 10px;" v-for="(item, index) in PrtOrderExpressList" :key="index">
				<div slot="header">
					<strong>{{ item.logistics }}({{ item.codeName }})</strong>
					<span v-if="0==item.isUse" style="color: red;">X不可用</span>
				</div>
				<div v-loading="item.loading">
					<el-descriptions border size="mini" v-if="item.descJson">
						<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson"
							:key="index2">{{ item2.value }}</el-descriptions-item>
					</el-descriptions>
					<el-alert v-if="item.err_msg" :title="item.err_msg" type="warning" :closable="false" show-icon>
					</el-alert>

					<el-table ref="multipleTable4" :data="item.data" highlight-current-row stripe :border="true"
						style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="carrier" :label="'Carrier'"></el-table-column>
						<el-table-column prop="carrierServiceLevel" :label="'CarrierServiceLevel'"></el-table-column>
						<el-table-column prop="channel" :label="'Channel'"></el-table-column>
						<el-table-column prop="price" :label="'Price'"></el-table-column>
						<el-table-column prop="" :label="'Details'" width="300">
							<template slot-scope="scope">
								<div>
									<div v-for="(val, key, index) in scope.row.feeDetail" :key="key">
										<span>{{key}}</span>：<span>{{val}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('i18nn_93f5ca01b006206c')">
							<template slot-scope="scope">
								<div>
									<el-button
										@click="submitOrderRateAction(item.code, $event, scope.row, scope.$index)"
										:disabled="0==item.isUse" type="primary" size="small" icon="el-icon-right">
										{{ $t('i18nn_3d6502c491aca17a') }}
									</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card> -->


		</div>

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button> -->
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {},
		data() {
			return {
				dialogFile: false,
				// loadingUpload: false,
				loading: false,
				// localFile:'',
				// loading_load: false,
				tableData: [],

				insuranceValue: "", //  保险  
				signatureService: "", // 签名
				channelsService:"",//渠道编码
				carrier:"",//渠道

				selOrderRateData: {}, //打单数据

				PrtOrderExpressData: "", //选择的渠道
				
				//限制地址
				CopeShipfromList: [],
				selCopeShip: '',
				form:{
					senderVo: {
						firstname: '',
						lastname: '',
						company: '',
						addr1: '',
						addr2: '',
						city: '',
						province: '',
						postcode: '',
						country: '',
						mobile: '',
						mail: ''
					},
				},
				

				// PriterOrderRate_show: false,
				// PriterOrderRate: [],
				// loading_rate_load: false,
				// PriterOrderRate1_err_msg: '',

				// PriterOrderRate2: [],
				// loading_rate_load2: false,
				// PriterOrderRate2_err_msg: '',

				PrtOrderExpressList: [], //所有渠道
				// loading_rate_load3: false,
				// PrtOrderExpressList_err_msg:[],

				selectOption: {
					biz_wh_lgskey_vite_ups: [],
					wh_signature_service: [],
					wh_channels:[]
				},

				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 500],
				// 	page_size: 500,
				// 	total: 0
				// },
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序
					lgsKeyCode: '',
					channel: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
		},
		methods: {
			// init() {
			// 	// this.filterData.seqNumber = '';
			// 	// this.tableData = [];
			// 	// this.getPageData();
			// },
			initData() {
				// this.tableData = [];
				// this.getPageData();
				this.insuranceValue = ""; //  保险
				this.signatureService = ""; // 签名
				this.channelsService = "";//渠道
				this.carrier = "";//渠道
				this.PrtOrderExpressData = "";
				this.PrtOrderExpressList = [];
				
				this.selCopeShip ="";
				this.CopeShipfromList = [];
				this.form.senderVo = null;

				this.selOrderRateData = this.row; //当前操作的数据
				this.queryLgsList(this.row);
			},

			//渠道查询
			queryLgsList() {
				this.getPriterOrderLgsList();
			},
			//选择渠道
			selLgsAction(){
				let selLgsData = this.PrtOrderExpressList.find(item => {
					return this.PrtOrderExpressData == item.code;
				});
				//签名
				if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
					this.selectOption.wh_signature_service = selLgsData.extendParamJson.signatures;
				} else {
					this.selectOption.wh_signature_service = [];
				}
				this.signatureService = null;
				//渠道编码
				if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
					this.selectOption.wh_channels = selLgsData.extendParamJson.channels;
					if(this.selectOption.wh_channels && this.selectOption.wh_channels.length>0){
						this.channelsService = this.selectOption.wh_channels[0].key;
					}
					
				} else {
					this.selectOption.wh_channels = [];
				}
				//渠道
				if(selLgsData.logistics){
					this.carrier = selLgsData.logistics;
				} else {
					this.carrier = "";
				}
				
				//限制地址
				this.getCopeShipFrom(this.PrtOrderExpressData);
				// this.queryLgsPrtOrderAction();
			},
			
			//cope改变
			ChangCopeShip(v) {
				this.form.senderVo = null;
				// this.form.senderVo.firstname = "";
				// // this.form.senderVo.company = ship.name;
				// // if (shipAddr.addresses && shipAddr.addresses1) {
				// this.form.senderVo.addr1 = "";
				// // }
				// // if (shipAddr.addresses2) {
				// 	this.form.senderVo.addr2 = "";
				// // }
				// this.form.senderVo.city = "";
				// this.form.senderVo.province = "";
				// this.form.senderVo.postcode = "";
				// this.form.senderVo.country = "";
				// this.form.senderVo.mobile = "";
				// // this.form.senderVo.uuid = shipAddr.uuid;
				
				let ship = this.CopeShipfromList.find(item => {
					return item.name == v;
				});
				console.log('ship', ship);
				let shipAddr = ship.address;
			
				if (shipAddr) {
					this.form.senderVo = {};
					this.form.senderVo.firstname = shipAddr.name;
					// this.form.senderVo.company = ship.name;
					// if (shipAddr.addresses && shipAddr.addresses1) {
					this.form.senderVo.addr1 = shipAddr.addresses1;
					// }
					if (shipAddr.addresses2) {
						this.form.senderVo.addr2 = shipAddr.addresses2;
					}
					this.form.senderVo.city = shipAddr.city;
					this.form.senderVo.province = shipAddr.provinceCode;
					this.form.senderVo.postcode = shipAddr.postalCode;
					this.form.senderVo.country = shipAddr.countryCode;
					this.form.senderVo.mobile = shipAddr.phone;
					// this.form.senderVo.uuid = shipAddr.uuid;
				} else {
					this.form.senderVo = null;
				}
				
				this.queryLgsPrtOrderAction();
			
			},
			//旧-->biz_wh_lgskey_cope_fedex 发货地址
			//新--> 'cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key
			//限制发货地址
			getCopeShipFrom(lgsCode) {
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhPriterOrderCopeShipfrom + "?lgsCode=" + lgsCode, {})
					.then(({
						data
					}) => {
						// console.log('查询cope_fedex，请求成功');
						// console.log(data);
						this.loading = false;
						if (200 == data.code) {
							this.CopeShipfromList = data.rows;
							if (this.CopeShipfromList[0] && this.CopeShipfromList[0].name) {
								this.selCopeShip = this.CopeShipfromList[0].name;
								this.ChangCopeShip(this.selCopeShip);
							}
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.errorData');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						this.loading = false;
						// console.log('查询cope_fedex接口，请求失败');
						this.$message.error(this.$t('tips.requestErrorData'));
					});
			},
			
			//-打单费率查询
			queryLgsPrtOrderAction() {
				this.tableData = [];
				// this.PrtOrderExpressData;
				this.getPriterOrderData(this.selOrderRateData.id, this.PrtOrderExpressData);
				// getPriterOrderData(exprId, code)
			},

			//打单费率查询
			// toOrderRateView(row) {
			// 	// event.stopPropagation();
			// 	// this.PriterOrderRate_show = true;
			// 	this.selOrderRateData = row;
			// 	this.ChangeSelOrderRateExpressData();
			// },
			//查询对应快递打单费率
			// ChangeSelOrderRateExpressData() {
			// 	this.insuranceValue = ""; //  保险  
			// 	this.signatureService = ""; // 签名
			// 	this.PrtOrderExpressList = [];
			// 	this.getPriterOrderUps2Rate(this.selOrderRateData.id);
			// },
			//提交打单
			submitOrderRateAction( event, row) {
				event.stopPropagation();
				console.log('row', row);
				// event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				// let parm = [this.currentSel2.id];
				let parm = {},
					url = "";
				//指定特定渠道
				let channel = null;
				if (row.channel) {//查询出的列表有channel，同上面选择的channel
					channel = row.channel;
				}
				//cope
				let rateId = null;
				if (row.rateId) {
					rateId = row.rateId;
				}
				url = this.$urlConfig.WhPriterOrderUps2BatchCreate;
				parm = {
					ids: [this.selOrderRateData.id],
					lgsKeyCode: this.PrtOrderExpressData,
					channel: channel,
					carrier: row.carrier ? row.carrier : null, 
					carrierServiceLevel: row.carrierServiceLevel ? row.carrierServiceLevel : null,
					rateId: rateId,
					insuranceValue: this.insuranceValue ? this.insuranceValue : null, //  保险
					signatureService: this.signatureService ? this.signatureService : null, // 签名
					senderVo: this.form.senderVo ? this.form.senderVo : null,
				};
				// }
				console.log('parm', parm);

				this.$confirm(this.$t('tips.confirmSubmit'), this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(url, parm, () => {
							// this.initData();
							// this.PriterOrderRate_show = false;
							this.dialogFile = false;
							this.$message.success(this.$t('tips.submitSuccess'));
							this.$emit('actionSuccess');
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {});
			},
			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//usp 渠道2
			// getPriterOrderUps2Rate(exprId) {
			// 	// this.PrtOrderExpressList = [];
			// 	// this.PrtOrderExpressList_err_msg = [];
			// 	this.getPriterOrderLgsList(list => {
			// 		this.PrtOrderExpressList = list;
			// 		// let PrtOrderExpressList = [];
			// 		// this.PrtOrderExpressList.forEach((item, index) => {
			// 		// 	item.loading = true;
			// 		// 	item.data = [];
			// 		// 	this.getPriterOrderUps2One(exprId, item, index);
			// 		// });
			// 	});
			// },

			//usp 渠道2 数据
			// getPriterOrderUps2RateData(exprId) {

			// 	this.getPriterOrderLgsList();

			// 	this.loading_rate_load3 = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhPriterOrderUps2Rate, {
			// 			exprId:exprId,
			// 			lgsKeyCode: "biz_wh_lgskey_vite_ups",
			// 			// channel: this.filterData.channel?this.filterData.channel:null,
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_ea8cd49587f7e54a'));
			// 			console.log(data);
			// 			this.loading_rate_load3 = false;
			// 			if (200 == data.code && data.data) {
			// 				this.PrtOrderExpressList = [data.data];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询信息失败,请重试';
			// 				}
			// 				// this.$message.warning(data.msg);
			// 				this.PrtOrderExpressList_err_msg = data.msg;
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询打单费率信息接口，请求失败');
			// 			this.loading_rate_load3 = false;
			// 			// this.$message.error('查询信息失败,请重试!');
			// 			this.PrtOrderExpressList_err_msg = '查询信息失败,请重试!';
			// 		});
			// },
			//查询费率-单个lgs
			getPriterOrderData(exprId, code) {
				this.loading = true;
				// item.data = [];
				// this.$set(this.PrtOrderExpressList,index,item);

				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2Rate, {
						exprId: exprId,
						lgsKeyCode: code,
						client: 'wms',
						insuranceValue: this.insuranceValue ? this.insuranceValue : null, //  保险
						signatureService: this.signatureService ? this.signatureService : null, // 签名
						channel: this.channelsService ? this.channelsService : null, // 渠道
						carrier: this.carrier ? this.carrier : null,
						senderVo: this.form.senderVo ? this.form.senderVo : null,
						// channel: this.filterData.channel?this.filterData.channel:null,
					})
					.then(({
						data
					}) => {
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.tableData = data.rows;
						} else {
							let err_msg = data.msg ? data.msg : this.$t('tips.queryErrorFailed');
							this.$message.warning(err_msg);
							// this.err_msg = err_msg;
						}
						// this.$set(this.PrtOrderExpressList, index, item);
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						this.$message.warning(this.$t('tips.queryRequestFailed'));
						// this.err_msg = '查询信息失败,请重试！';
						// this.$set(this.PrtOrderExpressList, index, item);
					});
			},
			// //查询费率-单个lgs
			// getPriterOrderData(exprId, item, index) {
			// 	// item.loading = true;
			// 	// item.data = [];
			// 	// this.$set(this.PrtOrderExpressList,index,item);

			// 	this.$http
			// 		.put(this.$urlConfig.WhPriterOrderUps2Rate, {
			// 			exprId: exprId,
			// 			lgsKeyCode: item.code,
			// 			client: 'wms',
			// 			insuranceValue: this.insuranceValue ? this.insuranceValue : null, //  保险
			// 			signatureService: this.signatureService ? this.signatureService : null, // 签名
			// 			// channel: this.filterData.channel?this.filterData.channel:null,
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			item.loading = false;
			// 			if (data && 200 == data.code && data.rows) {
			// 				item.data = data.rows;
			// 			} else {
			// 				let err_msg = data.msg ? data.msg : '查询信息失败,请重试';
			// 				item.err_msg = err_msg;
			// 			}
			// 			this.$set(this.PrtOrderExpressList, index, item);
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			item.loading = false;
			// 			item.err_msg = '查询信息失败,请重试！';
			// 			this.$set(this.PrtOrderExpressList, index, item);
			// 		});
			// },

			//lgs列表
			getPriterOrderLgsList() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse:'1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs列表信息');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							// callback(data.rows);
							this.PrtOrderExpressList = data.rows;
							// this.PrtOrderExpressList = [data.data];
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.queryErrorFailed');
							}
							this.$message.warning(data.msg);
							// this.PrtOrderExpressList_err_msg = [data.msg];
						}
					})
					.catch(error => {
						console.log(error);
						console.log('lgs列表信息接口，请求失败');
						this.loading = false;
						this.$message.error(this.$t('tips.queryRequestFailed'));
						// this.PrtOrderExpressList_err_msg = ['查询信息失败,请重试!'];
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 				// this.selectOption.biz_wh_lgskey_vite_ups = data.data['biz_wh_lgskey_vite_ups'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.rate-card-list /deep/.el-card__body {
		padding: 0;
	}
</style>
