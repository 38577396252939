<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_e6a250894a04db4c')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_75247a5e05331f7f')" append-to-body :visible.sync="dialog" :direction="'rtl'" size="900px">
		<div v-loading="loading_load">
			<div style="margin-bottom: 5px;padding: 10px;">
				<!-- <span class="red">上传完预计60秒内匹配，文件越大等待越长</span> -->
				<el-alert type="warning">
					<div slot="title" style="font-size: 16px; ">{{$t('i18nn_daaaeb1b7b22b126')}}</div>
					<div style="font-size: 14px; ">
						<div>上传完后，预计60秒内匹配完成，文件越大等待越长；最多显示5000条；</div>
					</div>
				</el-alert>
				<el-button type="success" icon="el-icon-refresh" size="small" @click="initData()">{{$t('i18nn_9e0c510eaed20bc7')}}</el-button>
			</div>
			<el-tabs type="border-card">
			  <el-tab-pane>
					<div slot="label">
						<span>
							{{$t('i18nn_379c907d12f5c9f5')}}
						</span>
						<el-badge :value="successTotal"></el-badge>
					</div>
					<div>
						<whLabelFileAutoMatchDetPage :openTime="successOpenTime" :parentId="id" :statusList="['60']"></whLabelFileAutoMatchDetPage>
					</div>
				</el-tab-pane>
				<el-tab-pane>
					<div slot="label">
						<span>
							{{$t('i18nn_b14b20e17d756cb2')}}
						</span>
						<el-badge :value="failTotal"></el-badge>
					</div>
					<div>
						<whLabelFileAutoMatchDetPage :openTime="failOpenTime" :parentId="id" :statusList="['70']"></whLabelFileAutoMatchDetPage>
					</div>
				</el-tab-pane>
				<el-tab-pane>
					<div slot="label">
						<span>
							{{$t('i18nn_cce168301447d1ba')}}
						</span>
						<el-badge :value="waitTotal"></el-badge>
					</div>
					<div>
						<whLabelFileAutoMatchDetPage :openTime="waitOpenTime" :parentId="id" :statusList="['30','35','40','50','55']"></whLabelFileAutoMatchDetPage>
					</div>
				</el-tab-pane>
			</el-tabs>
			
		</div>
		

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import whLabelFileAutoMatchDetPage from '@/components/StorageCenter/shipments/whLabelFileAutoMatchDetPage.vue';

	export default {
		props: {
			openTime: {},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		components: {
			whLabelFileAutoMatchDetPage
		},
		data() {
			return {
				dialog: false,
				loading_load: false,
				// tableData: [],
				// // 30-拆分完成
				// // 35-处理中
				// // 40-处理成功
				// // 50-处理失败
				// // 55-label pdf上传成功
				// // 60-匹配成功
				// // 70-匹配失败
				successTotal:"0",
				failTotal:"0",
				waitTotal:"0",
				// allTabData:[],
				
				successOpenTime:"",
				failOpenTime:"",
				waitOpenTime:"",
				// successData:[],
				// failData:[],
				
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime whLabelFileAutoMatchDet');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				// if (this.id) {
				// 	// this.tableData = [];
				// 	this.allTabData = [];
				// 	// this.failData = [];
				// 	this.getListData();
				// } else {
				// 	this.$message.warning("SKU为空");
				// }
				this.getCountData();
				this.successOpenTime = new Date().getTime();
				this.failOpenTime = new Date().getTime();
				this.waitOpenTime = new Date().getTime();
			},

			//请求分页数据
			getCountData() {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.whMatchingLabelPdfRecTotal+"/"+this.id, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.data) {
							let countData = data.data;
							this.successTotal = countData.success;
							this.failTotal = countData.fail;
							this.waitTotal = countData.handing;
							// this.tableData = data.rows;
							// let tableData = data.rows;
							// // 30-拆分完成
							// // 35-处理中
							// // 40-处理成功
							// // 50-处理失败
							// // 55-label pdf上传成功
							// // 60-匹配成功
							// // 70-匹配失败
							
							// let allTabData = [{
							// 	name:this.$t('i18nn_379c907d12f5c9f5'),
							// 	data:[]
							// },{
							// 	name:this.$t('i18nn_b14b20e17d756cb2'),
							// 	data:[]
							// },{
							// 	name:this.$t('i18nn_cce168301447d1ba'),
							// 	data:[]
							// }];
							
							// tableData.forEach(item=>{
							// 	if('60'==item.status){
							// 		allTabData[0].data.push(item);
							// 	} else if('70'==item.status){
							// 		allTabData[1].data.push(item);
							// 	} else if(['30','35','40','50','55'].includes(item.status)){
							// 		allTabData[2].data.push(item);
							// 	}
							// });
							
							// this.allTabData = allTabData;
							// let successData = [];
							// let failData = [];
							// tableData.forEach(item=>{
							// 	if(!!item.relationNo){
							// 		successData.push(item);
							// 	} else {
							// 		failData.push(item);
							// 	}
							// });
							// this.successData = successData;
							// this.failData = failData;
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
